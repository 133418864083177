import { useHistory } from "react-router-dom";
import { NewIcon } from "@lysaab/ui-2";
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
} from "kbar";
import { RenderResults } from "./RenderResults";
import { getNavLink } from "../../hooks/useCountryUrls";
import { LOGOUT_PAGE_URL } from "../../pages/logout/LogoutPage";
import { TRANSFERS_MENU_PAGE_URL } from "../../pages/transfersMenu/TransfersMenuPage";
import { OVERVIEW_PAGE_URL } from "../../pages/overview/OverviewPage";
import { PROFILE_PAGE_URL } from "../../pages/profile/ProfilePage";
import { MESSAGES_PAGE_URL } from "../../pages/messages/MessagesPage";
import { INVITE_PAGE_URL } from "../../pages/invite/InvitePage";
import { SWITCH_USER_SELECTION_PAGE_URL } from "../../countries/sweden/switchUser/SwitchUserSelection";
import { SWISH_DEPOSIT_PAGE_URL } from "../../countries/sweden/pages/deposits/swish/SwishDepositPage";
import { AUTOGIRO_DEPOSIT_PAGE_URL } from "../../countries/sweden/pages/deposits/Autogiro/deposit/AutogiroDepositPage";
import { DEPOSITS_OVERVIEW_URL } from "../../pages/deposits/overview/Recommendation";
import { BANKGIRO_DEPOSIT_PAGE_URL } from "../../countries/sweden/pages/deposits/bankgiro/BankgiroDepositStory";
import { WITHDRAWAL_PAGE_URL } from "../../pages/withdrawal/overview/WithdrawalPage";
import { MONTHLY_OVERVIEW_PAGE } from "../../pages/deposits/monthly/overview/MonthlyOverview";
import { CREATE_ACCOUNT_INTRO_URL } from "../../countries/sweden/pages/createAccount/createAccountIntroStory/CreateAccountIntroStory";

import "./CmdK.scss";
import { DARK_MODE, Store } from "../../Store";

interface Props {
  children: React.ReactNode;
}

export const CmdK: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  const actions = [
    {
      id: "overview",
      name: "Översikt",
      // shortcut: ["ö"],
      keywords: "back",
      // section: "Navigation",
      perform: () => {
        history.push(getNavLink(OVERVIEW_PAGE_URL));
      },
      icon: <NewIcon.Bank />,
      subtitle: "Gå tillbaks till översikten",
    },
    {
      id: "transfers",
      name: "Överföringar",
      // shortcut: ["v"],
      keywords: "transfers",
      // section: "Navigation",
      perform: () => {
        history.push(getNavLink(TRANSFERS_MENU_PAGE_URL));
      },
      icon: <NewIcon.Transfer />,
    },
    {
      id: "profile",
      name: "Kundinfo",
      // shortcut: ["k"],
      keywords: "profile",
      // section: "Navigation",
      perform: () => {
        history.push(getNavLink(PROFILE_PAGE_URL));
      },
      icon: <NewIcon.Profile />,
    },
    {
      id: "messages",
      name: "Meddelanden",
      // shortcut: ["m"],
      keywords: "messages",
      // section: "Navigation",
      perform: () => {
        history.push(getNavLink(MESSAGES_PAGE_URL));
      },
      icon: <NewIcon.Message />,
    },
    {
      id: "invite",
      name: "Bjud in en vän",
      // shortcut: ["b"],
      keywords: "invite",
      // section: "Navigation",
      perform: () => {
        history.push(getNavLink(INVITE_PAGE_URL));
      },
      icon: <NewIcon.Invite />,
    },
    {
      id: "switch",
      name: "Byt användare",
      // shortcut: ["y"],
      keywords: "switch users företag privatperson",
      // section: "Navigation",
      perform: () => {
        history.push(getNavLink(SWITCH_USER_SELECTION_PAGE_URL));
      },
      icon: <NewIcon.Accounts />,
    },
    {
      id: "help",
      name: "Få hjälp",
      // shortcut: ["?"],
      keywords:
        "help customer service faq questions frågor svar kundtjänst kontakt kontakta oss",
      perform: () => {
        if (window._elev) {
          window._elev.openHome();
        }
      },
      icon: <NewIcon.HelpOutline />,
    },
    {
      id: "deposits",
      name: "Insättningar",
      parent: "transfers",
      // shortcut: ["i"],
      keywords: "swish tink bankgiro deposits",
      perform: () => {
        history.push(getNavLink(DEPOSITS_OVERVIEW_URL));
      },
    },
    {
      id: "deposits-autogiro",
      parent: "deposits",
      name: "Föranmält konto",
      // shortcut: ["i", "f"],
      keywords: "insättning autogiro deposit",
      perform: () => {
        history.push(getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL));
      },
    },
    {
      id: "deposits-swish",
      parent: "deposits",
      name: "Swish",
      // shortcut: ["i", "s"],
      keywords: "insättning swish deposit mobile",
      perform: () => {
        history.push(getNavLink(SWISH_DEPOSIT_PAGE_URL));
      },
    },
    {
      id: "deposits-bankgiro",
      parent: "deposits",
      name: "Bankgiro med OCR",
      // shortcut: ["i", "b"],
      keywords: "insättning bankgiro deposit ocr",
      perform: () => {
        history.push(getNavLink(BANKGIRO_DEPOSIT_PAGE_URL));
      },
    },
    {
      id: "withdrawals",
      name: "Uttag",
      parent: "transfers",
      // shortcut: ["u"],
      keywords: "bankkonton withdrawals",
      perform: () => {
        history.push(getNavLink(WITHDRAWAL_PAGE_URL));
      },
    },
    {
      id: "monthly",
      name: "Månadsspara",
      parent: "transfers",
      // shortcut: ["å"],
      keywords: "månadsspar monthly spar",
      perform: () => {
        history.push(getNavLink(MONTHLY_OVERVIEW_PAGE));
      },
    },
    {
      id: "create-account",
      name: "Öppna konto",
      // shortcut: ["k"],
      keywords: "nytt skapa lysakonto create new",
      perform: () => {
        history.push(getNavLink(CREATE_ACCOUNT_INTRO_URL));
      },
    },
    {
      id: "logout",
      name: "Logga ut",
      // shortcut: ["q"],
      keywords: "log out sign out signout quit logout sign off signoff",
      perform: () => {
        history.push(getNavLink(LOGOUT_PAGE_URL));
      },
    },
    {
      id: "darkmode",
      name: "Växla mellan ljust/mörkt läge",
      // shortcut: ["d"],
      keywords: "dark mode darkmode toggle night",
      perform: () => {
        // TODO: Let users set DARK_MODE to SYSTEM
        if (document.documentElement.getAttribute("data-theme") === "dark") {
          Store.setValue(DARK_MODE, "LIGHT");
          document.documentElement.setAttribute("data-theme", "light");
        } else {
          Store.setValue(DARK_MODE, "DARK");
          document.documentElement.setAttribute("data-theme", "dark");
        }
      },
    },
  ];

  return (
    <KBarProvider actions={actions}>
      <KBarPortal>
        <KBarPositioner className="cmd-k">
          <KBarAnimator className="cmd-k-animator">
            <KBarSearch className="cmd-k-search" />
            <RenderResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {children}
    </KBarProvider>
  );
};
