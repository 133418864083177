import {
  Button,
  FlashContext,
  LysaLink,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { DateTime } from "luxon";
import { ReactNode, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../components/TranslatedText";
import { MONTHLY_AGREEMENTS_PAGE_URL } from "../agreements/MonthlyAgreements";
import { CompoundAccountId } from "../../../../data/dataAccounts";
import {
  dataMonthlyPayments,
  MonthlyPaymentWithBankInfo,
} from "../../../../data/dataMonthlyPayments";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { Page, PageHeader } from "../../../Page";
import { MONTHLY_DEPOSITS_URL } from "../create/MonthlyStory";
import { Deposit } from "./Deposit";
import "./MonthlyOverview.scss";
import { useAccounts } from "../../../../hooks/useAccounts";
import { RouteAwareToggle } from "../../../../components/route/RouteAwareToggle";
import { useAccountFilterParams } from "../../../../hooks/useAccountFilterParams";

const messages = defineMessages({
  updateSuccessFlash: {
    id: "monthly.overview.flash.update.success",
  },
  updateErrorFlash: {
    id: "monthly.overview.flash.update.error",
  },
});

export const MONTHLY_OVERVIEW_PAGE = "/monthly";
const FLASH_TIMER = 2000;

interface Props {
  children?: ReactNode;
}

export const MonthlyOverview = ({ children }: Props) => {
  const [activePayments, setActivePayments] =
    useState<MonthlyPaymentWithBankInfo[]>();
  const { accounts } = useAccounts();
  const flashContext = useContext(FlashContext);
  const intl = useIntl();
  const { hasAccountFilter, accountFilterParams } = useAccountFilterParams();

  useEffect(() => {
    dataMonthlyPayments.getAllMonthlyPayments().then(({ monthlyPayments }) => {
      setActivePayments(monthlyPayments);
    });
  }, []);

  if (typeof activePayments === "undefined" || !accounts) {
    return <Spinner />;
  }

  const sortedAccounts = [
    ...accounts.investmentAccounts,
    ...accounts.savingsAccounts,
  ].sort((a, b) =>
    DateTime.fromISO(a.created).diff(DateTime.fromISO(b.created)).toMillis()
  );

  return (
    <Page>
      <PageHeader>
        <h1>
          <TranslatedText id="monthly.overview.header" />
        </h1>
      </PageHeader>
      <div className="monthly-overview">
        <section className="monthly-overview-section">
          <GridRow>
            <GridCol xsmall={12}>
              <p>
                <TranslatedText id="monthly.overview.text" />{" "}
                <RouteAwareToggle
                  path={getNavLink(MONTHLY_AGREEMENTS_PAGE_URL)}
                >
                  <TranslatedText
                    id="monthly.overview.mandateText"
                    values={{
                      link: (parts) => (
                        <LysaLink
                          component={Link}
                          to={getNavLink(MONTHLY_AGREEMENTS_PAGE_URL)}
                        >
                          {parts}
                        </LysaLink>
                      ),
                    }}
                  />
                </RouteAwareToggle>
              </p>
            </GridCol>
            <GridCol xsmall={12}>
              <Button
                component={Link}
                to={{
                  pathname: getNavLink(MONTHLY_DEPOSITS_URL),
                  search: hasAccountFilter ? accountFilterParams : undefined,
                }}
                label={<TranslatedText id="monthly.overview.link" />}
              />
            </GridCol>
          </GridRow>
        </section>
        {children}
        {activePayments.length > 0 && (
          <section className="account-list-wrapper">
            <h3>
              <TranslatedText id="monthly.overview.mineHeader" />
            </h3>
            <GridRow>
              {activePayments
                .sort((a, b) => a.accountId.localeCompare(b.accountId))
                .map((deposit) => {
                  const account = sortedAccounts.find(
                    (acc) => acc.accountId === deposit.accountId
                  );
                  if (!account) {
                    return null;
                  }
                  return (
                    <Deposit
                      key={deposit.accountId + deposit.externalBankAccount}
                      account={account}
                      payment={deposit}
                      amountLimit={deposit.amountLimit}
                      deleteMonthlyPayment={(
                        accountId: CompoundAccountId,
                        externalBankAccount: string
                      ) => {
                        return dataMonthlyPayments
                          .deleteMonthlyPayment({
                            accountId,
                            externalBankAccount,
                          })
                          .then(setActivePayments);
                      }}
                      skipMonthlyPayment={(
                        accountId: CompoundAccountId,
                        externalBankAccount: string
                      ) => {
                        return dataMonthlyPayments
                          .skipMonthlyPayment({
                            accountId,
                            externalBankAccount,
                          })
                          .then(setActivePayments);
                      }}
                      resetMonthlyPayment={(
                        accountId: CompoundAccountId,
                        externalBankAccount: string
                      ) => {
                        return dataMonthlyPayments
                          .resetMonthlyPayment({
                            accountId,
                            externalBankAccount,
                          })
                          .then(setActivePayments);
                      }}
                      updateMonthlyPayment={(
                        accountId: CompoundAccountId,
                        externalBankAccount: string,
                        amount: number,
                        depositDay: number
                      ) => {
                        return dataMonthlyPayments
                          .updateMonthlyPayment({
                            accountId,
                            externalBankAccount,
                            amount,
                            depositDay,
                          })
                          .then((resp) => {
                            setActivePayments(resp);
                            flashContext.pushFlash({
                              type: SNACKBAR_TYPES.SUCCESS,
                              text: intl.formatMessage(
                                messages.updateSuccessFlash
                              ),
                              timer: FLASH_TIMER,
                            });
                          })
                          .catch(() => {
                            flashContext.pushFlash({
                              type: SNACKBAR_TYPES.ERROR,
                              text: intl.formatMessage(
                                messages.updateErrorFlash
                              ),
                              timer: FLASH_TIMER,
                            });
                          });
                      }}
                    />
                  );
                })}
            </GridRow>
          </section>
        )}
      </div>
    </Page>
  );
};
