import { BrowserRouter } from "react-router-dom";
import { AccessabilityTabber } from "@lysaab/ui-2/components/accessabilityTabber/AccessabilityTabber";
import { LocalizationContextProvider } from "./context/LocalizationContext";
import { TopLevelRouter } from "./TopLevelRouter";
import { LayoutContextProvider } from "./context/LayoutContext";
import { App } from "./App";
import { PerformanceContextProvider } from "./context/PerformanceContext";
import { NotificationsContextProvider } from "./context/NotificationsContext";
import { UserContextProvider } from "./context/UserContext";
import { BurgerMenuContextProvider } from "./components/burgerMenu/BurgerMenuContext";
import { RouteContextProvider } from "./components/route/RouteContext";
import { API, cache, Refocus } from "@lysaab/ui-2";
import { FeatureContextProvider } from "./context/FeatureContext";
import { FeatureConfigurationContextProvider } from "./context/FeatureConfigurationContext";
import { UserStorageContextProvider } from "./context/UserStorageContext";
import { ReactQueryProvider } from "./ReactQueryProvider";
import { useEffect, useState } from "react";
import { ApiClientProvider } from "@lysaab/shared";

declare global {
  interface Window {
    resetApp: () => void;
  }
}

function AppProviders() {
  const [key, setKey] = useState(0);

  useEffect(() => {
    window.resetApp = () => {
      setKey(Math.random() * 200);
      cache.clear();
    };
  }, []);
  return (
    <RouteContextProvider key={key}>
      <LocalizationContextProvider>
        <ReactQueryProvider>
          <UserContextProvider>
            <FeatureContextProvider>
              <FeatureConfigurationContextProvider>
                <LayoutContextProvider>
                  <UserStorageContextProvider>
                    <PerformanceContextProvider>
                      <NotificationsContextProvider>
                        <BurgerMenuContextProvider>
                          <ApiClientProvider apiClient={API}>
                            <AccessabilityTabber />
                            <BrowserRouter>
                              <Refocus />
                              <App>
                                <TopLevelRouter />
                              </App>
                            </BrowserRouter>
                          </ApiClientProvider>
                        </BurgerMenuContextProvider>
                      </NotificationsContextProvider>
                    </PerformanceContextProvider>
                  </UserStorageContextProvider>
                </LayoutContextProvider>
              </FeatureConfigurationContextProvider>
            </FeatureContextProvider>
          </UserContextProvider>
        </ReactQueryProvider>
      </LocalizationContextProvider>
    </RouteContextProvider>
  );
}

export default AppProviders;
