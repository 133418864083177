import { useEffect, useCallback, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import {
  CardList,
  Spinner,
  TextInput,
  Typography,
  useDebounceFn,
} from "@lysaab/ui-2";
import * as H from "history";
import "./BankSelection.scss";
import { TranslatedText } from "../TranslatedText";
import { BankListItem } from "./BankListItem";
import * as fuzzyFind from "./fuzzyfind";
import { RefineSearchCard } from "./RefineSearchCard";
import { TinkAvailableBank, dataTink } from "../../data/dataTink";
import { MissingBankCard } from "./MissingBankCard";
import { useLocation } from "react-router";
import { ADD_EXTERNAL_TINK_URL } from "../../pages/withdrawal/addAccountTink/AddAccountTinkStory";

export const messages = defineMessages({
  searchPlaceholder: {
    id: "tink.bank-selection.search.placeholder",
  },
  searchLabel: {
    id: "tink.bank-selection.search.label",
  },
});

export interface BankMissing {
  isMissingBankItem: boolean;
}

const MAX_BANKS = 10;

interface BankSelectionProps {
  onComplete: (tinkBank: TinkAvailableBank) => void;
  missingBank?: H.LocationDescriptor<H.LocationState>;
  filterBanksFunction?: (bank: TinkAvailableBank) => boolean;
  intro?: JSX.Element | string;
  missingBankHeader: JSX.Element | string;
  missingBankDescription: JSX.Element | string;
}

const extractArrayFromObject = <T,>(
  obj: Array<T> | { [key: string]: Array<T> }
): Array<T> => {
  if (Array.isArray(obj)) {
    return obj;
  }
  if (typeof obj === "object") {
    const [key] = Object.keys(obj);
    if (key && Array.isArray(obj[key])) {
      return obj[key];
    }
  }
  return [];
};

export function BankSelection({
  onComplete,
  missingBank,
  filterBanksFunction,
  intro,
  missingBankHeader,
  missingBankDescription,
}: BankSelectionProps) {
  const intl = useIntl();
  const location = useLocation();
  const [allBanks, setAllBanks] = useState<Array<TinkAvailableBank>>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [fuzzyResult, setFuzzyResult] = useState<Array<TinkAvailableBank>>();

  const fetchAllBanks = useCallback(() => {
    dataTink.getAllBanks().then((banks: TinkAvailableBank[]) => {
      const filteredAndSortedBanks = extractArrayFromObject(banks).filter(
        filterBanksFunction ?? (() => true)
      );

      setAllBanks(filteredAndSortedBanks);
      setFuzzyResult(filteredAndSortedBanks);
    });
  }, [filterBanksFunction]);

  useEffect(() => {
    fetchAllBanks();
  }, [fetchAllBanks]);

  const search = useCallback(
    (term: string) => {
      const result = fuzzyFind
        .metadataFilter(
          term,
          allBanks.map((bank) => bank.name)
        )
        .map((res: fuzzyFind.Match) => allBanks[res.index]);

      setFuzzyResult(result);
    },
    [allBanks]
  );

  const debouncedSearch = useDebounceFn(search, 200);

  useEffect(() => {
    if (!searchTerm) {
      return;
    }

    debouncedSearch(searchTerm);
  }, [debouncedSearch, searchTerm]);

  if (allBanks.length === 0) {
    return <Spinner />;
  }

  const isWithdrawlPath = location.pathname.includes(ADD_EXTERNAL_TINK_URL);

  const originalBanksList = searchTerm ? fuzzyResult : allBanks;

  const banksToShow = (originalBanksList || [])
    .filter(
      (bank) => !isWithdrawlPath || bank.accountHolderVerification !== false
    )
    .slice(0, MAX_BANKS);

  const isSearchable = allBanks.length > MAX_BANKS;

  return (
    <div className="bank-selection">
      <Typography type="h2">
        <TranslatedText id="tink.bank-selection.choose-bank" />
      </Typography>

      {intro && <p className="bank-search-intro">{intro}</p>}
      {isSearchable && (
        <div className="search-input">
          <TextInput
            label={intl.formatMessage(messages.searchLabel)}
            value={searchTerm}
            placeholder={intl.formatMessage(messages.searchPlaceholder)}
            onChange={(value) => setSearchTerm(value)}
          />
        </div>
      )}
      <CardList className="bank-selection-list">
        {isSearchable && (
          <RefineSearchCard matches={originalBanksList?.length ?? 0} />
        )}
        {banksToShow.map((bank) => (
          <BankListItem bank={bank} onComplete={onComplete} key={bank.id} />
        ))}
        {missingBank && (
          <MissingBankCard
            missingBank={missingBank}
            header={missingBankHeader}
            description={missingBankDescription}
          />
        )}
      </CardList>
    </div>
  );
}
