import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState, useEffect } from "react";
import {
  dataLifePensionMove,
  ScriveStatusByCaseIdEntry,
} from "../../../../../data/dataLifePensionMove";

const SCRIVE_STATUS_FOR_CASE_KEY = "scriveStatusesForCase";

/**
 * Backend has more fine grained statuses than the UI needs.
 * This is the UI state to show for the current backend state.
 */
export type UiStatus = "IDLE" | "WAITING" | "SIGNING" | "SIGNED" | "RETRY";
/**
 * Backend data object for scrive status with added UiStatus
 */
export type UiScriveStatus = ScriveStatusByCaseIdEntry & {
  uiStatus: UiStatus;
};

type StartSigningParams = {
  moveId: string;
  /**
   *The URL to redirect to after signing is done.
   */
  callbackUrl: string;
};

/**
 * Sometimes we know that a document was signed in scrive before the backend knows.
 * In those cases we want to display the move as signed instead of a retry button that would
 * confuse the user.
 */
type SignedMoveIdHint = string | null | undefined;
type AwaitingScriveForMoveId = string | undefined;

/**
 * Calculate UI-status based on backend statuses
 */
const uiScriveStatus = (
  status: ScriveStatusByCaseIdEntry,
  signedMoveIdHint: SignedMoveIdHint,
  awaitingScriveForMoveId: AwaitingScriveForMoveId,
  statusIsStale: boolean
): UiStatus => {
  const { status: backendStatus } = status;

  if (
    // While we are waiting for a scrive session to start and status is stale.
    // If we solely rely on statusIsStale we will show WAITING for the initial
    // render before the user pressed any button.
    (awaitingScriveForMoveId === status.accountId && statusIsStale) ||
    // Or if we are waiting for a scrive session to start and backend has not yet turned to ONGOING.
    (awaitingScriveForMoveId === status.accountId &&
      backendStatus !== "ONGOING") ||
    // INITIATED and UPDATABLE are states where the backend is prepare a scrive session.
    backendStatus === "INITIATED" ||
    backendStatus === "UPDATABLE"
  ) {
    // then set UI status to WAITING.
    return "WAITING";
  }
  // DOWNLOAD and COMPLETED are states where the backend has received the signed document.
  if (backendStatus === "DOWNLOAD" || backendStatus === "COMPLETED") {
    return "SIGNED";
  }
  if (
    backendStatus === "REJECTED" ||
    backendStatus === "FAILED" ||
    backendStatus === "CANCELLED" ||
    backendStatus === "TIMEDOUT" ||
    // If the document is opened but the move is not signed by the current user, we should show retry state,
    // unless we know with signedMoveIdHint before the backend that the move was just signed.
    // Then we don't want to confuse the user with a retry-button until the server has caught up.
    (backendStatus === "ONGOING" &&
      status.documentOpened &&
      signedMoveIdHint !== status.accountId) ||
    // If we are ONGOIN but not waiting for server to initiate a session
    // for this move, ONGOING is for an old session, so show the RETRY state.
    (backendStatus === "ONGOING" &&
      awaitingScriveForMoveId !== status.accountId)
  ) {
    return "RETRY";
  }
  // No session active, show IDLE state.
  if (!backendStatus || backendStatus === "NOT_FOUND") {
    return "IDLE";
  }
  // If we are ONGOING with fresh data, then a scrive session has started.
  if (
    awaitingScriveForMoveId === status.accountId &&
    backendStatus === "ONGOING"
  ) {
    return "SIGNING";
  }

  throw new Error(`Unexpected scrive status from backend: ${backendStatus}`);
};

const areAllMovesSigned = (statuses: ScriveStatusByCaseIdEntry[]) =>
  statuses.every((status) => status.status === "COMPLETED");

/**
 * Create a UiScriveStatus from a ScriveStatusByCaseIdEntry
 */
const mapScriveStatus = (
  scriveStatus: ScriveStatusByCaseIdEntry,
  signedMoveIdHint: SignedMoveIdHint,
  awaitingScriveForMoveId: AwaitingScriveForMoveId,
  statusIsStale: boolean
): UiScriveStatus => ({
  ...scriveStatus,
  uiStatus: uiScriveStatus(
    scriveStatus,
    signedMoveIdHint,
    awaitingScriveForMoveId,
    statusIsStale
  ),
});

/**
 * Manage the signing status with the backend.
 * Will keep data up-to-date with the backend.
 */
export const useScriveStatusForCase = ({
  caseId,
  signedMoveIdHint,
  enabled = true,
  onSigningStarted,
}: {
  caseId: string;
  signedMoveIdHint?: SignedMoveIdHint;
  enabled?: boolean;
  onSigningStarted?: (scriveStatus: UiScriveStatus) => void;
}) => {
  const queryClient = useQueryClient();
  // After a scrive signing has been requested for a move, we wait until backend
  // has the correct state. Then we can redirect the user to Scrive.
  // This flag is used to know that we are waiting to redirect to Scrive for a certain move
  // when backend enters the expected state for it.
  const [awaitingScriveForMoveId, setAwaitingScriveForMoveId] = useState<
    string | undefined
  >();
  // Track when we receive a response from backend where all moves are signed.
  // Then we set a state to rerun the hook where we disable the poll, because then we have nothing more to poll for.
  const [allMovesAreSigned, setAllMovesAreSigned] = useState(false);

  const startSigningMutation = useMutation({
    mutationFn: ({ moveId, callbackUrl }: StartSigningParams) => {
      const callbackUrlWithMoveId = new URL(callbackUrl);
      // React Native does not support searchParams.set without a polyfill,
      // but append should really be fine in this case.
      // If this becomes a problem (callbackUrl already containing the search param so we get a duplicate),
      // there is a polyfill we can use.
      // Add signedMoveId to the redirected page so we can know which move was signed.
      // Backend might not have caught up yet when we redirect to the callbackUrl.
      callbackUrlWithMoveId.searchParams.append("signedMoveId", moveId);
      return dataLifePensionMove.startScriveSigning({
        moveId,
        callbackUrl: callbackUrlWithMoveId.href,
      });
    },
    onMutate: ({ moveId }) => {
      // We are now waiting for `moveId` to enter expected state in backend,
      // then we will redirect to Scrive signing for this move.
      // Cancel any ongoing queries for this case as the data will be stale on arrival.
      queryClient.cancelQueries({
        queryKey: [SCRIVE_STATUS_FOR_CASE_KEY, caseId],
      });
      setAwaitingScriveForMoveId(moveId);
    },
    onSuccess: () => {
      // We should have new status, invalidate all existing data.
      // Invalidate in onSuccess because react query does not invalidate data
      // while the query is disabled, and we disable the query during the start request.
      queryClient.invalidateQueries({ queryKey: [SCRIVE_STATUS_FOR_CASE_KEY] });
    },
    onError: (err) => {
      // If error happens we are not expecting backend to enter desired state anymore.
      setAwaitingScriveForMoveId(undefined);
      throw err;
    },
  });

  const { data: scriveStatuses, isStale } = useQuery({
    queryKey: [SCRIVE_STATUS_FOR_CASE_KEY, caseId],
    queryFn: () => dataLifePensionMove.getScriveStatusByCaseId(caseId),
    // No reason to fetch if we don't have caseId
    // Also hold poll if we are waiting for a signing to start, the data will be stale as it arrives.
    enabled:
      enabled &&
      Boolean(caseId) &&
      !startSigningMutation.isPending &&
      !allMovesAreSigned,
    refetchInterval: 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });
  // React query does not invalidate data when the query is disabled.
  // Therefore we need a calculated state that takes the running start request
  // into consideration as we disable the query while it runs.
  const scriveStatusIsStale = isStale || startSigningMutation.isPending;
  // Use useFocusEffect to only navigate if the component is focused.
  useEffect(() => {
    if (!scriveStatuses) {
      return;
    }
    // If all moves are signed we should not poll anymore.
    if (areAllMovesSigned(scriveStatuses)) {
      setAllMovesAreSigned(true);
    }
    // If we are waiting for a move to enter a "ready to sign"-state in the backend,
    // and backend is at that state, we can redirect to Scrive signing.
    if (awaitingScriveForMoveId) {
      // Find status for the move we are waiting for
      const awaitingMoveStatus = scriveStatuses.find(
        (scriveStatus) => scriveStatus.accountId === awaitingScriveForMoveId
      );
      // If the move we are waiting for is in SIGNING state it is ready to be signed,
      // notify callback that signing can start.
      if (
        awaitingMoveStatus &&
        uiScriveStatus(
          awaitingMoveStatus,
          signedMoveIdHint,
          awaitingScriveForMoveId,
          scriveStatusIsStale
        ) === "SIGNING"
      ) {
        setAwaitingScriveForMoveId(undefined);
        onSigningStarted?.(
          mapScriveStatus(
            awaitingMoveStatus,
            signedMoveIdHint,
            awaitingScriveForMoveId,
            scriveStatusIsStale
          )
        );
      }
    }
  }, [
    awaitingScriveForMoveId,
    onSigningStarted,
    scriveStatusIsStale,
    scriveStatuses,
    signedMoveIdHint,
  ]);

  // Normalize backend data to include uiStatus
  const normalizedScriveStatuses = useMemo(() => {
    if (scriveStatuses) {
      return scriveStatuses.map(
        (s): UiScriveStatus =>
          mapScriveStatus(
            s,
            signedMoveIdHint,
            awaitingScriveForMoveId,
            scriveStatusIsStale
          )
      );
    }
  }, [
    awaitingScriveForMoveId,
    scriveStatusIsStale,
    scriveStatuses,
    signedMoveIdHint,
  ]);

  return { data: normalizedScriveStatuses, startSigning: startSigningMutation };
};
