import { useState, type FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { getUserAge } from "../utils/userAge";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { Ingress } from "../components/ingress/Ingress";
import { MAX_AGE } from "../TransferContext";
import { LegalEntityType } from "../../../../../data/dataLogin";
import { ReactComponent as TreeIllustration } from "./tree.svg";
import { IntroInfo } from "./IntroInfo";
import "./IntroShort.scss";
import { useUser } from "../../../../../context/UserContext";

interface Props {
  next: () => void;
  exit: () => void;
}

export const IntroShort: FunctionComponent<Props> = ({ next, exit }) => {
  const intl = useIntl();
  const user = useUser();
  const age = getUserAge(user.tin);
  const [showInfo, setShowInfo] = useState(false);

  if (age && age > MAX_AGE) {
    return (
      <article className="transfer-pension-intro transfer-pension-intro-short">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText id={"sweden.transfer-pension.intro.block.header"} />
          </Typography>
          <Ingress>
            <TranslatedText
              id={"sweden.transfer-pension.intro.block.reason.age"}
              values={{
                max: (text) => {
                  return <span>{MAX_AGE}</span>;
                },
              }}
            />
          </Ingress>
        </header>
        <Button
          className="exit-button"
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.exit.button",
          })}
          onClick={exit}
        />
      </article>
    );
  }

  if (user.legalEntityType === LegalEntityType.CORPORATION) {
    return (
      <article className="transfer-pension-intro transfer-pension-intro-short">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText id={"sweden.transfer-pension.intro.block.header"} />
          </Typography>
          <Ingress>
            <TranslatedText
              id={"sweden.transfer-pension.intro.block.reason.corporation"}
            />
          </Ingress>
        </header>
        <Button
          className="exit-button"
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.exit.button",
          })}
          onClick={exit}
        />
      </article>
    );
  }

  return (
    <>
      <article className="transfer-pension-intro transfer-pension-intro-short">
        <header>
          <TreeIllustration className="title-image" />
          <Typography type="display-small">
            <TranslatedText
              id={"sweden.transfer-pension.intro.header"}
              values={{
                first: (text) => {
                  return text;
                },
                second: (text) => {
                  return text;
                },
              }}
            />
          </Typography>
          <Typography type="body" className="ingress">
            <TranslatedText id={"sweden.transfer-pension.intro.ingress"} />
          </Typography>
        </header>
        <ul className="usp-list">
          <li className="list-item">
            <span className="icon-circle">
              <NewIcon.Check primaryColor="#1840e3" />
            </span>
            <span className="list-item-text">
              <Typography type="label">
                <TranslatedText
                  id={"sweden.transfer-pension.intro.list-item-1.header"}
                />
              </Typography>
            </span>
          </li>
          <li className="list-item">
            <span className="icon-circle">
              <NewIcon.Check primaryColor="#1840e3" />
            </span>
            <span className="list-item-text">
              <Typography type="label">
                <TranslatedText
                  id={"sweden.transfer-pension.intro.list-item-2.header"}
                />
              </Typography>
            </span>
          </li>
          <li className="list-item">
            <span className="icon-circle">
              <NewIcon.Check primaryColor="#1840e3" />
            </span>
            <span className="list-item-text">
              <Typography type="label">
                <TranslatedText
                  id={"sweden.transfer-pension.intro.list-item-3.header"}
                />
              </Typography>
            </span>
          </li>
        </ul>
      </article>
      <div className="transfer-pension-intro-actions">
        <Button
          className="next"
          block
          variant="primary"
          onClick={next}
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.button",
          })}
        />
        <Button
          block
          variant="secondary"
          onClick={() => setShowInfo(true)}
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.info-button",
          })}
        />
        {showInfo && <IntroInfo onClose={() => setShowInfo(false)} />}
      </div>
    </>
  );
};
