import { FunctionComponent } from "react";
import { HistoricFeeSavingsSummary } from "../../../../data/dataFees";
import { defineMessages, useIntl } from "react-intl";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";
import { FeeHeaderCell } from "../components/feeHeaderCell/FeeHeaderCell";
import { FeeBodyCell } from "../components/feeBodyCell/FeeBodyCell";
import { CostBodyCell } from "../components/costBodyCell/CostBodyCell";
import { CostHeaderCell } from "../components/costHeaderCell/CostHeaderCell";

interface Props {
  summary: HistoricFeeSavingsSummary;
}

const messages = defineMessages({
  rebatedDiscretionaryFee: {
    id: "fees.feeTables.summary.savingsAccounts.rebatedDiscretionaryFee",
  },
  paidDiscretionaryFee: {
    id: "fees.feeTables.summary.savingsAccounts.paidDiscretionaryFee",
  },
  totalFee: {
    id: "fees.feeTables.summary.savingsAccounts.totalFee",
  },
  avgDiscretionaryCost: {
    id: "fees.feeTables.summary.savingsAccounts.avgDiscretionaryCost",
  },
});

export const SummaryTableSavings: FunctionComponent<Props> = ({ summary }) => {
  const intl = useIntl();

  const total = summary.paidDiscretionaryFee + summary.rebatedDiscretionaryFee;

  return (
    <LysaTable>
      <thead>
        <tr>
          <FeeHeaderCell
            text={intl.formatMessage(messages.paidDiscretionaryFee)}
          />
          <FeeHeaderCell
            text={intl.formatMessage(messages.rebatedDiscretionaryFee)}
          />
          <FeeHeaderCell text={intl.formatMessage(messages.totalFee)} />
          <CostHeaderCell
            text={intl.formatMessage(messages.avgDiscretionaryCost)}
          />
        </tr>
      </thead>
      <tbody>
        <tr>
          <FeeBodyCell
            value={summary.paidDiscretionaryFee}
            label={intl.formatMessage(messages.paidDiscretionaryFee)}
          />
          <FeeBodyCell
            value={summary.rebatedDiscretionaryFee}
            label={intl.formatMessage(messages.rebatedDiscretionaryFee)}
          />
          <FeeBodyCell
            value={total}
            label={intl.formatMessage(messages.totalFee)}
          />
          <CostBodyCell
            value={summary.avgDiscretionaryCost}
            label={intl.formatMessage(messages.avgDiscretionaryCost)}
          />
        </tr>
      </tbody>
    </LysaTable>
  );
};
