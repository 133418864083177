import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { AddAccountTinkContext } from "../../AddAccountTinkContext";
import { ADD_EXTERNAL_TINK_URL, BASE_ROUTES } from "../../AddAccountTinkStory";
import { dataWithdrawals } from "../../../../../data/dataWithdrawals";
import { TinkAccount } from "../../../../../data/dataTink";
import { AccountSelectionError } from "../components/AccountSelectionError";
import { LysaCountry } from "@lysaab/shared";
import { useCountry } from "../../../../../context/LocalizationContext";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../../../data/dataCustomerTracking";

interface Props {
  next: () => void;
}

const getAccountLabel = (account: TinkAccount, country: LysaCountry) => {
  if (country === LysaCountry.SWEDEN) {
    if (account.name) {
      return `${account.name} - ${account.accountNumber}`;
    }

    return account.accountNumber;
  }
  return `${account.name} - ${account.iban}`;
};

export function AccountSelection({ next }: Props) {
  const TinkContext = useContext(AddAccountTinkContext);
  const history = useHistory();
  const intl = useIntl();
  const country = useCountry();
  const selectedTinkAccount = TinkContext.state.tinkSelectedAccount;
  const accountVerificationReportId =
    TinkContext.state.accountVerificationReportId;
  const formRef = useRef<LysaFormRef>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ServerError<unknown>>();

  useEffect(() => {
    if (!TinkContext.state.tinkAccounts) {
      history.push(getNavLink(ADD_EXTERNAL_TINK_URL));
    }
  }, [history, TinkContext.state.tinkAccounts]);

  useEffect(() => {
    if (TinkContext.state.tinkAccounts) {
      dataCustomerTrackingService.postEvent({
        domain: FeatureDomain.TRANSFERS,
        subDomain: SubDomain.WITHDRAWAL,
        eventName: "withdrawalAccountSelection",
      });
    }
  }, [TinkContext.state.tinkAccounts]);

  if (!TinkContext.state.tinkAccounts || isLoading) {
    return <Spinner />;
  }

  const sortedTinkAccounts = TinkContext.state.tinkAccounts.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  if (sortedTinkAccounts.length === 0) {
    return (
      <div>
        <h2>
          <TranslatedText id="widthdrawals.account.tink.story.account-selection.no-accounts.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <p>
            <TranslatedText id="widthdrawals.account.tink.story.account-selection.no-accounts.text" />
          </p>
        </Snackbar>
        <Button
          block
          onClick={() => history.push(getNavLink(BASE_ROUTES.BANK_SELECTION))}
          label={
            <TranslatedText id="widthdrawals.account.tink.story.account-selection.no-accounts.button" />
          }
        />
      </div>
    );
  }

  return (
    <div>
      <h2>
        <TranslatedText id="widthdrawals.account.tink.story.account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            !selectedTinkAccount ||
            !accountVerificationReportId ||
            typeof error !== "undefined"
          ) {
            return;
          }

          setIsLoading(true);

          dataWithdrawals
            .addWithdrawalAccountTink(
              selectedTinkAccount.iban,
              accountVerificationReportId
            )
            .then(() => {
              setIsLoading(false);
              next();
            })
            .catch((error: ServerError<unknown>) => {
              setError(error);
              setIsLoading(false);
            });
        }}
      >
        <Card>
          {error && <AccountSelectionError error={error} />}

          <RadioGroup
            alternatives={sortedTinkAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(account, country),
                  value: account.accountNumber,
                };
              }
            )}
            value={
              selectedTinkAccount
                ? {
                    text: getAccountLabel(selectedTinkAccount, country),
                    value: selectedTinkAccount.accountNumber,
                  }
                : undefined
            }
            header={intl.formatMessage({
              id: "widthdrawals.account.tink.story.account-selection.account.label",
            })}
            onChange={(alt) => {
              if (error) {
                setError(undefined);
              }
              TinkContext.setState({
                tinkSelectedAccount: sortedTinkAccounts?.find(
                  (account) => account.accountNumber === alt.value
                ),
              });
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "widthdrawals.account.tink.story.account-selection.account.required",
                })
              ),
            ]}
          />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="widthdrawals.account.tink.story.account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
