import { useReducer, createContext, FunctionComponent } from "react";
import * as React from "react";
import { CompoundAccount } from "../../../../../data/dataAccounts";
import { Consent } from "../../../../../data/dataAutogiro";
import { ExternalAccount } from "../../../../../data/dataWithdrawals";
import { TinkAccount, TinkAvailableBank } from "../../../../../data/dataTink";

export interface MonthlyState {
  tinkBank?: TinkAvailableBank;
  selectedLysaAccount?: CompoundAccount;
  tinkAccounts?: TinkAccount[];
  amount?: string;
  selectedTinkAccount?: TinkAccount;
  withdrawalDay: number;
  externalAccount?: ExternalAccount | Consent;
}

export interface MonthlyContextProps {
  state: MonthlyState;
  setState: (newState: Partial<MonthlyState>) => void;
}

export interface withMonthlyProps {
  Monthly: MonthlyContextProps;
}

const DEFAULT_MONTHLY_WITHDRAWAL_DAY = 29;

export const MonthlyContext = createContext<MonthlyContextProps>(
  {} as MonthlyContextProps
);

function stateReducer(state: MonthlyState, newState: Partial<MonthlyState>) {
  return { ...state, ...newState };
}

export const MonthlyContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    withdrawalDay: DEFAULT_MONTHLY_WITHDRAWAL_DAY,
  });

  return (
    <MonthlyContext.Provider value={{ state, setState }}>
      {children}
    </MonthlyContext.Provider>
  );
};
