import {
  Card,
  Form,
  LysaFormRef,
  MinValidator,
  MoneyInput,
  Button,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../../context/LocalizationContext";
import { UserContext } from "../../../../../../../context/UserContext";
import { dataAutogiro } from "../../../../../../../data/dataAutogiro";
import { getNavLink } from "../../../../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../../../../hooks/useSafeNavigation";
import {
  AutogiroDepositContext,
  AutogiroDepositState,
} from "../AutogiroDepositContext";
import { EventTracker } from "../../../../../../../components/eventTracker/EventTracker";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../../pages/deposits/overview/Recommendation";
import {
  FeatureDomain,
  SubDomain,
  TrackerEvent,
  dataCustomerTrackingService,
} from "../../../../../../../data/dataCustomerTracking";
import { AUTOGIRO_DEPOSIT_PAGE_URL } from "../AutogiroDepositPage";
import { useHistory } from "react-router";
import { useMinDepositAmount } from "../../../../../../../experiments/minDeposit/useMinDepositAmount";

interface Props {
  next: (state: AutogiroDepositState) => void;
}

const messages = defineMessages({
  amountLabel: {
    id: "sweden.deposits.autogiro.story.amount-selection.amount.label",
  },
  amountRequired: {
    id: "sweden.deposits.autogiro.story.amount-selection.amount.required",
  },
  minAmount: {
    id: "sweden.deposits.autogiro.story.amount-selection.amount.min-amount",
  },
  noAmount: {
    id: "sweden.deposits.autogiro.story.amount-selection.amount.no-amount",
  },
});

export function TinkAmountSelection({ next }: Props) {
  const minDepositAmount = useMinDepositAmount();
  const [postError, setPostError] = useState(false);
  const autogiroDepositContext = useContext(AutogiroDepositContext);
  const localizationContext = useContext(LocalizationContext);
  const userContext = useContext(UserContext);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const safeNavigation = useSafeNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    /* This indicates that you have refreshed the page */
    if (
      typeof autogiroDepositContext.state.lysaAccount === "undefined" &&
      !autogiroDepositContext.state.isSentToServer
    ) {
      history.replace(getNavLink(AUTOGIRO_DEPOSIT_PAGE_URL));
    }
  }, [
    autogiroDepositContext.state.isSentToServer,
    autogiroDepositContext.state.lysaAccount,
    history,
  ]);

  useEffect(() => {
    dataCustomerTrackingService.postEvent({
      domain: FeatureDomain.TRANSFERS,
      subDomain: SubDomain.DEPOSIT,
      eventName: "autogiroAmountSelection",
    });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="autogiro-deposit-account-selection">
      <Typography type="h2">
        <TranslatedText id="sweden.deposits.autogiro.story.amount-selection.header" />
      </Typography>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (userContext.state.readOnly) {
            safeNavigation(getNavLink(DEPOSITS_OVERVIEW_URL));
          }

          dataCustomerTrackingService.postEvent({
            domain: FeatureDomain.TRANSFERS,
            subDomain: SubDomain.DEPOSIT,
            eventName: "clickedAutogiroDepositButton",
            payload: {
              amount:
                autogiroDepositContext.state.amount !== undefined &&
                !isNaN(autogiroDepositContext.state.amount)
                  ? autogiroDepositContext.state.amount.toString()
                  : "",
            },
          });

          const externalAccount =
            autogiroDepositContext.state.selectedTinkAccount?.accountNumber ||
            autogiroDepositContext.state.externalAccount?.externalBankAccount;
          if (
            formRef.current?.isInvalid ||
            !autogiroDepositContext.state.lysaAccount ||
            !externalAccount ||
            !autogiroDepositContext.state.amount
          ) {
            return;
          }
          setIsLoading(true);
          setPostError(false);
          dataAutogiro
            .addPayment(autogiroDepositContext.state.lysaAccount.accountId, {
              externalBankAccount: externalAccount,
              amount: autogiroDepositContext.state.amount,
            })
            .then(() => {
              if (autogiroDepositContext.state.amount) {
                dataCustomerTrackingService.postEvent({
                  domain: FeatureDomain.TRANSFERS,
                  subDomain: SubDomain.DEPOSIT,
                  eventName: "autogiroDepositSuccess",
                });
                EventTracker.track({
                  event: TrackerEvent.DEPOSIT,
                  message: intl.formatNumber(
                    autogiroDepositContext.state.amount,
                    {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  ),
                });
              }
              const currentDepositState = { ...autogiroDepositContext.state };
              autogiroDepositContext.setState({
                isSentToServer: true,
                amount: undefined,
                externalAccount: undefined,
                lysaAccount: undefined,
                tinkAccounts: undefined,
                selectedTinkAccount: undefined,
                tinkBank: undefined,
              });
              next(currentDepositState);
            })
            .catch(() => setPostError(true))
            .finally(() => setIsLoading(false));
        }}
      >
        <Card>
          <MoneyInput
            label={intl.formatMessage(messages.amountLabel)}
            currency={localizationContext.state.currency}
            value={
              autogiroDepositContext.state.amount &&
              !isNaN(autogiroDepositContext.state.amount)
                ? autogiroDepositContext.state.amount.toString()
                : ""
            }
            onChange={(event) => {
              autogiroDepositContext.setState({
                amount: parseInt(event),
              });
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.amountRequired)
              ),
              new MinValidator(
                minDepositAmount,
                intl.formatMessage(messages.minAmount, {
                  minAmount: intl.formatNumber(minDepositAmount, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                  }),
                })
              ),
            ]}
          />
        </Card>
        {postError && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.deposits.autogiro.story.amount-selection.post-error" />
          </Snackbar>
        )}
        {userContext.state.readOnly && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.deposits.autogiro.story.amount-selection.read-only" />
          </Snackbar>
        )}
        <Button
          type="submit"
          block
          label={
            userContext.state.readOnly ? (
              <TranslatedText id="sweden.deposits.autogiro.story.amount-selection.read-only-button" />
            ) : (
              <TranslatedText id="sweden.deposits.autogiro.story.amount-selection.button" />
            )
          }
        />
      </Form>
    </div>
  );
}
