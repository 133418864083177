import { Alternative, Select } from "@lysaab/ui-2";
import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  InvestmentAccountId,
  dataAccounts,
} from "../../../../data/dataAccounts";
import {
  HistoricFeeAccountResponse,
  HistoricFeeClosedAccountResponse,
} from "../../../../data/dataFees";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../components/lysaTableTextCell/LysaTableTextCell";
import { AccountRow } from "./accountRow/AccountRow";
import { accountTableMessages } from "./AccountTableMessages";
import { LocalizationContext } from "../../../../context/LocalizationContext";

const messages = defineMessages({
  showAllAccounts: {
    id: "fees.feeTables.accountTable.showAllAccounts",
  },
  selectPlaceholder: {
    id: "fees.feeTables.accountTable.selectPlaceholder",
  },
  selectLabel: {
    id: "fees.feeTables.accountTable.selectLabel",
  },
});

interface Props {
  accountsData: (
    | HistoricFeeAccountResponse
    | HistoricFeeClosedAccountResponse
  )[];
}

const ALL_ACCOUNTS_VALUE = "all";

export type AccountAlternative = Alternative<
  InvestmentAccountId | typeof ALL_ACCOUNTS_VALUE
>;

export const AccountTable: FunctionComponent<Props> = ({ accountsData }) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const [accountAlts, setAccountAlts] = useState<AccountAlternative[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<AccountAlternative>({
    text: intl.formatMessage(messages.showAllAccounts),
    value: ALL_ACCOUNTS_VALUE,
  });
  const [selectedAccountData, setSelectedAccountData] = useState<
    HistoricFeeAccountResponse | HistoricFeeClosedAccountResponse
  >();

  useEffect(() => {
    dataAccounts.getAccounts().then((response) => {
      const accountAlternatives: AccountAlternative[] = accountsData.map(
        (account) => {
          if (isClosedAccount(account)) {
            return {
              text: account.accountName,
              value: account.accountId,
            };
          } else {
            const accountName =
              response.find((account1) => {
                return account1.accountId === account.accountId;
              })?.name || account.accountId;
            return {
              text: accountName,
              value: account.accountId,
            };
          }
        }
      );
      setAccountAlts([
        {
          text: intl.formatMessage(messages.showAllAccounts),
          value: ALL_ACCOUNTS_VALUE,
        },
        ...accountAlternatives,
      ]);
    });
  }, [accountsData, intl, localizationContext.state.currency]);

  return (
    <Fragment>
      <Select
        placeholder={intl.formatMessage(messages.selectPlaceholder)}
        alternatives={accountAlts}
        value={selectedAccount}
        onChange={(value) => {
          setSelectedAccount(value);
          setSelectedAccountData(
            accountsData.find((accountData) => {
              return accountData.accountId === value.value;
            })
          );
        }}
        label={intl.formatMessage(messages.selectLabel)}
      />
      <LysaTable>
        <thead>
          <tr>
            <LysaTableTextCell
              value={intl.formatMessage(accountTableMessages.accountHeader)}
              header={true}
            />
            {isClosedAccount(accountsData[0]) && (
              <Fragment>
                <LysaTableTextCell
                  value={intl.formatMessage(accountTableMessages.opened)}
                  header={true}
                />
                <LysaTableTextCell
                  value={intl.formatMessage(accountTableMessages.closed)}
                  header={true}
                />
              </Fragment>
            )}
            <LysaTableTextCell
              value={intl.formatMessage(accountTableMessages.discretionaryFees)}
              header={true}
            />
            {accountsData.some((account) =>
              account.hasOwnProperty("insurance")
            ) && (
              <LysaTableTextCell
                value={intl.formatMessage(accountTableMessages.insuranceFees)}
                header={true}
              />
            )}
            <LysaTableTextCell
              value={intl.formatMessage(accountTableMessages.fundFees)}
              header={true}
            />
            <th>{intl.formatMessage(accountTableMessages.total)}</th>
          </tr>
        </thead>
        <tbody>
          {typeof selectedAccountData !== "undefined" &&
          selectedAccount.value !== ALL_ACCOUNTS_VALUE ? (
            <AccountRow accountData={selectedAccountData} />
          ) : (
            accountsData.map((accountdata) => {
              return (
                <AccountRow
                  key={accountdata.accountId}
                  accountData={accountdata}
                />
              );
            })
          )}
        </tbody>
      </LysaTable>
    </Fragment>
  );
};

export function isClosedAccount(
  account: HistoricFeeAccountResponse | HistoricFeeClosedAccountResponse
): account is HistoricFeeClosedAccountResponse {
  return account.hasOwnProperty("closed");
}
