/**
 * A custom hook for tracking user events with the dataCustomerTrackingService.
 *
 * This hook provides a flexible way to track events with automatic execution on component mount
 * and manual triggering capabilities.
 *
 * @param domain - The feature domain where the event occurred (e.g., TRANSFERS, ACCOUNTS)
 * @param subDomain - The specific sub-domain within the feature (e.g., TINK, PLAID)
 * @param eventName - The name of the event being tracked (e.g., "initBankSelection", "confirmTransfer")
 * @param options - Optional configuration:
 *   - once: If true, the event will only be tracked once during component lifecycle (default: false)
 *   - metadata: Additional data to send with the event (default: {})
 *   - onSuccess: Callback function when tracking succeeds
 *   - onError: Callback function when tracking fails, receives the error object
 *
 */

import { useEffect, useRef } from "react";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../data/dataCustomerTracking";

type TrackEventOptions = {
  once?: boolean;
  metadata?: Record<string, any>;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useTrackEvent = (
  domain: FeatureDomain,
  subDomain: SubDomain,
  eventName: string,
  options: TrackEventOptions = {}
) => {
  const { once = false, metadata = {}, onSuccess, onError } = options;
  const hasTrackedRef = useRef(false);

  useEffect(() => {
    // Skip if "once" is true and we've already tracked
    if (once && hasTrackedRef.current) {
      return;
    }

    const trackEvent = async () => {
      try {
        await dataCustomerTrackingService.postEvent({
          domain,
          subDomain,
          eventName,
          ...metadata,
        });

        if (once) {
          hasTrackedRef.current = true;
        }

        onSuccess?.();
      } catch (error) {
        console.error("Failed to track event:", error);
        onError?.(error as Error);
      }
    };

    trackEvent();
  }, [domain, subDomain, eventName, once, metadata, onSuccess, onError]);

  // Expose a method to manually trigger the event
  const trackEventManually = async () => {
    try {
      await dataCustomerTrackingService.postEvent({
        domain,
        subDomain,
        eventName,
        ...metadata,
      });

      onSuccess?.();
      return true;
    } catch (error) {
      console.error("Failed to track event:", error);
      onError?.(error as Error);
      return false;
    }
  };

  return { trackEventManually };
};
