import { Button, Snackbar, SNACKBAR_TYPES, Typography } from "@lysaab/ui-2";
import { useState, type FunctionComponent } from "react";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { ReactComponent as GraphIllustration } from "./graph.svg";
import { ReactComponent as RocketIllustration } from "./rocket.svg";
import { ReactComponent as WelcomeIllustration } from "./welcome.svg";
import { FAQ, FaqItemProps } from "../components/faq/FAQ";
import { Help } from "../components/help/Help";
import { Modal } from "../../../../../components/modal/Modal";
import { useIntl } from "react-intl";
import "./IntroInfo.scss";

// TODO: Translate these.

const faqItems: FaqItemProps[] = [
  {
    title: "Vilka pensioner kan jag flytta?",
    content:
      "De flesta icke kollektivavtalade tjänstepensioner går att flytta, men vissa försäkringar kan ha särskilda villkor som påverkar möjligheten. När du startar flyttprocessen i vår app eller på hemsidan hjälper vi dig att enkelt se vilka pensioner som kan flyttas och tar hand om resten åt dig.",
  },
  {
    title: "Varför ska jag flytta min pension?",
    content:
      "När du flyttar din pension till Lysa får du dina pengar att växa med låga avgifter och investeringar i breda, billiga indexfonder. Vi anpassar automatiskt risken utifrån din ålder och när utbetalningarna närmar sig – så att du alltid har rätt risknivå utan att behöva tänka på det.",
  },
  {
    title: "Hur vet jag var jag har mina tjänstepensioner?",
    content:
      "Vi hjälper dig att identifiera var du har dina tjänstepensioner direkt i flyttflödet och kontrollerar om de är flyttbara. Du kan även logga in på minPension.se för en komplett översikt över alla dina pensioner och nuvarande försäkringsbolag.",
  },
  {
    title: "Behöver jag göra något själv under processen?",
    content:
      "Nej, vi sköter allt åt dig – från att kontakta din tidigare arbetsgivare till att hantera flytten med försäkringsbolaget. Om något behövs från dig så hör vi av oss direkt.",
  },
  {
    title: "Hur lång tid tar en flytt?",
    content:
      "Att flytta en pension tar normalt cirka tre månader, beroende på vilket bolag du flyttar ifrån och hur snabbt det går att samla in alla nödvändiga signaturer. Under flyttprocessen är dina pengar fortsatt investerade och du ligger därför inte utanför marknaden mer än några bankdagar. ",
  },
];

const FeeInfo: FunctionComponent = () => {
  const [readMoreFee, setReadMoreFee] = useState(false);
  const intl = useIntl();
  return readMoreFee ? (
    <Snackbar type={SNACKBAR_TYPES.INFO}>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.one" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.two" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.three" />
      </Typography>
      <Typography type="body-small">
        <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.footnote" />
      </Typography>
    </Snackbar>
  ) : (
    <Button
      variant="outlined"
      block
      onClick={() => setReadMoreFee(true)}
      label={intl.formatMessage({
        id: "sweden.transfer-pension.intro.why.sub-section-one.show-fees-button",
      })}
    />
  );
};

interface Props {
  onClose: () => void;
}

export const IntroInfo: FunctionComponent<Props> = ({ onClose }) => {
  const intl = useIntl();
  const [readMoreFee, setReadMoreFee] = useState(false);
  return (
    <Modal
      header={intl.formatMessage({
        id: "sweden.transfer-pension.intro.why.header",
      })}
      showModal
      onClose={onClose}
      closeOnOverlayClick
      hideScrollTopButton
    >
      <section className="transfer-pension-intro-info why">
        <div className="why-sub-section">
          <RocketIllustration className="illustration" />
          <Typography type="h3" className="why-sub-section-header">
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-one.header" />
          </Typography>
          <Typography>
            <TranslatedText id="sweden.transfer-pension.intro.info.why.sub-section-one.body" />
          </Typography>
          <FeeInfo />
        </div>
        <div className="why-sub-section grey-bg">
          <GraphIllustration className="illustration" />
          <Typography type="h3" className="why-sub-section-header">
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-two.header" />
          </Typography>
          <Typography>
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-two.body" />
          </Typography>
        </div>
        <div className="why-sub-section">
          <WelcomeIllustration className="illustration" />
          <Typography type="h3" className="why-sub-section-header">
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-three.header" />
          </Typography>
          <Typography>
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-three.body" />
          </Typography>
        </div>
        <section className="contact grey-bg">
          <FAQ faqItems={faqItems} />
          <Help />
        </section>
        {false && (
          <Modal
            header={intl.formatMessage({
              id: "sweden.transfer-pension.intro.read-more.fees.heading",
            })}
            showModal={readMoreFee}
            onClose={() => {
              setReadMoreFee(false);
            }}
            closeOnOverlayClick
          >
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.one" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.two" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.three" />
            </Typography>
            <Typography type="body-small">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.footnote" />
            </Typography>
          </Modal>
        )}
      </section>
    </Modal>
  );
};
