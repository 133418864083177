import { useEffect } from "react";

export const useTimeout = ({
  timerStartedAt,
  timeoutMs,
  onTimeout,
}: {
  timerStartedAt: number | undefined;
  timeoutMs: number;
  onTimeout: () => void;
}) => {
  useEffect(() => {
    if (timerStartedAt) {
      const triggerTimeoutInSeconds = timeoutMs - (Date.now() - timerStartedAt);
      const timeoutId = setTimeout(() => {
        onTimeout();
      }, triggerTimeoutInSeconds);
      return () => clearTimeout(timeoutId);
    }
  }, [onTimeout, timeoutMs, timerStartedAt]);
};
