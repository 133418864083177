import { useLoadLegalEntityExperiments } from "@lysaab/shared";

type ValueOf<T> = T[keyof T];

// Example: { WELCOME_MODAL: "app-welcome-modal.enabled" }
export const experimentKeys = {
  MIN_DEPOSIT: "min-deposit-amount",
  TRANSFER_PENSION_INTRO_SIZE: "transfer-pension-intro-size",
} as const;

/*
Example:
{
  [experimentKeys.WELCOME_MODAL]: {
    defaultValue: "false"
  },
}
*/
export const experimentConfig = {
  [experimentKeys.MIN_DEPOSIT]: {
    defaultValue: "1000",
  },
  [experimentKeys.TRANSFER_PENSION_INTRO_SIZE]: {
    defaultValue: "long",
  },
} as const;

// These are automatically loaded on login
// If you have an experiment that does not use legalEntityId as the subjecId,
// you'll need to implement loading yourself
// Example: [experimentKeys.WELCOME_MODAL]
const legalEntityIdExperiments: Array<ValueOf<typeof experimentKeys>> = [
  experimentKeys.MIN_DEPOSIT,
  experimentKeys.TRANSFER_PENSION_INTRO_SIZE,
];

export function useLoadSignInExperiments() {
  return useLoadLegalEntityExperiments(legalEntityIdExperiments);
}
