import {
  useEffect,
  useState,
  type FunctionComponent,
  type ReactNode,
} from "react";
import { useTimeout } from "../../hooks/useTimeout";

interface DelayedRenderProps {
  children: ReactNode;
  delayMs: number;
  fallback?: ReactNode;
}

export const DelayedRender: FunctionComponent<DelayedRenderProps> = ({
  children,
  delayMs,
  fallback = null,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [timerStartedAt, setTimerStartedAt] = useState<number>();
  useTimeout({
    timerStartedAt,
    timeoutMs: delayMs,
    onTimeout: () => {
      setIsReady(true);
    },
  });
  useEffect(() => {
    setTimerStartedAt(Date.now());
  }, []);
  return <>{isReady ? children : fallback}</>;
};
