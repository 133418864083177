import * as React from "react";
import { RouteProps, Route, matchPath } from "react-router";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";
import { OVERVIEW_PAGE_URL } from "../../pages/overview/OverviewPage";
import { YEARLY_REVIEW_URL } from "../../pages/yearlyReview/YearlyReviewStory";
import { CLOSE_LYSA_CUSTOMER_URL } from "../../pages/closeLysaCustomerAccountStory/CloseLysaCustomerStory";
import { ADD_EXTERNAL_TINK_URL } from "../../pages/withdrawal/addAccountTink/AddAccountTinkStory";
import { useFeatureContext } from "../../context/FeatureContext";
import { MESSAGES_PAGE_URL } from "../../pages/messages/MessagesPage";
import { CONTACT_PAGE_URL } from "../../pages/contact/ContactPage";
import { REVIEW_ACCOUNT_URL } from "../../pages/reviewAccount/ReviewAccountStory";
import { LOGOUT_PAGE_URL } from "../../pages/logout/LogoutPage";
import { SWITCH_USER_SELECTION_PAGE_URL } from "../../countries/sweden/switchUser/SwitchUserSelection";
import { SWITCH_USER_PAGE_URL } from "../../countries/sweden/switchUser/SwitchUser";
import { NotFoundPage } from "../../pages/NotFoundPage";
import { RedirectLogin } from "./RedirectLogin";
import { RedirectHome } from "./RedirectHome";

function pathBlockedByYearlyReview(
  routeProps: Omit<RouteProps, "component" | "render" | "children">
): boolean {
  // pathsNotBlockedByYearlyReview can not be defined in module body, it trips up the order of module inclusions by webpack,
  // perhaps due to the circular imports due to importing from pages.
  // We had cases when webpack included modules in the bundle in such an order that OVERVIEW_PAGE_URL was referenced
  // before its definition.
  const pathsNotBlockedByYearlyReview = [
    OVERVIEW_PAGE_URL,
    CLOSE_LYSA_CUSTOMER_URL,
    ADD_EXTERNAL_TINK_URL,
    YEARLY_REVIEW_URL,
    MESSAGES_PAGE_URL,
    CONTACT_PAGE_URL,
    REVIEW_ACCOUNT_URL,
    LOGOUT_PAGE_URL,
    SWITCH_USER_SELECTION_PAGE_URL,
    SWITCH_USER_PAGE_URL,
  ];

  const allowed = pathsNotBlockedByYearlyReview.some((allowedPath) =>
    matchPath(getNavLink(allowedPath), routeProps)
  );

  // Any path that is not explicitly allowed is considered blocked
  return !allowed;
}

type PrivateRouteProps = Omit<RouteProps, "component" | "render"> & {
  condition?: boolean;
};
/** Route with access guard, only allowing signed in users */
export const PrivateRoute: React.FunctionComponent<
  React.PropsWithChildren<PrivateRouteProps>
> = ({ children, condition = true, ...routeProps }) => {
  const isSignedIn = useIsSignedIn();
  const [featureState] = useFeatureContext();

  const blockedByYearlyReview =
    featureState.yearlyReviewLockRoutes &&
    pathBlockedByYearlyReview(routeProps);

  return (
    <Route
      {...routeProps}
      render={(props) => {
        if (!isSignedIn) {
          return <RedirectLogin location={props.location} />;
        } else if (blockedByYearlyReview) {
          return <RedirectHome />;
        } else if (!condition) {
          return <NotFoundPage />;
        }

        return children;
      }}
    />
  );
};

/** Route allowing all users */
export const PublicRoute = Route;

/** Route with access guard, only allowing signed out users */
export const NonPrivateRoute: React.FunctionComponent<
  React.PropsWithChildren<Omit<RouteProps, "component" | "render">>
> = (routeProps) => {
  const isSignedIn = useIsSignedIn();

  if (isSignedIn) {
    return <RedirectHome />;
  }

  return <Route {...routeProps} />;
};
