import { API, cache, encode } from "@lysaab/ui-2";
import { InvestmentAccountId } from "./dataAccounts";
import { LegalEntityId } from "./dataUser";

type MandateId = number & { readonly isMandateId: true };
type DirectDebitReferenceId = number & {
  readonly isDirectDebitReferenceId: true;
};
export type ExternalAccountNumber = string & {
  readonly isExternalAccountNumber: true;
};

export interface MandateResponse {
  id: MandateId;
  externalAccountNumber: ExternalAccountNumber;
  legalEntityId: LegalEntityId;
  debtorName: string;
  created: string;
  removed?: string;
}

export type MonthlyDepositAccountRestrictionResponse =
  | GeneralMonthlyDepositAccountRestrictionResponse
  | MonthlyDepositAccountRestrictionConfirmedOwner;

interface GeneralMonthlyDepositAccountRestrictionResponse {
  externalAccountNumber: ExternalAccountNumber;
  mandateId: MandateId;
  limited: boolean;
  amountLimit: number;
  nextPossibleTargetDate: string;
  bank?: string;
}

interface MonthlyDepositAccountRestrictionConfirmedOwner {
  externalAccountNumber: ExternalAccountNumber;
  limited: false;
  nextPossibleTargetDate: string;
  bank?: string;
}

interface MonthlyDepositResponse {
  id: string;
  accountId: InvestmentAccountId;
  amount: number;
  created: string;
  externalAccountNumber: ExternalAccountNumber;
  mandateId: MandateId;
  nextDeduction: string;
  targetPaymentDay: number;
  updateCutOffTime: string;
  isSkipped: boolean;
  updated: string;
}

interface CreateMonthlyDepositRequest {
  accountId: InvestmentAccountId;
  externalAccountNumber: string;
  amount: number;
  targetPaymentDay: number;
}

interface ValidExternalAccountResponse {
  isValid: boolean;
}

export interface PendingMonthlyDepositTransactionResponse {
  directDebitReferenceId: DirectDebitReferenceId;
  amount: string;
  mandateId: MandateId;
  mandateCreated: string;
  debtorName: string;
  legalEntityId: LegalEntityId;
  debitorExternalAccountNumber: string;
  initated: string;
  deductionDate: string;
  transactionStatus: string;
  externalAccountNumber: string;
  accountId: InvestmentAccountId;
  calculateConcludeDate: string;
}

export const GET_ACOUNT_RESTRICTIONS_URL =
  "/direct-debit/monthly-deposit/accounts/restrictions";

export const dataDirectDebit = {
  postAddMandate: (externalAccountNumber: string) => {
    return API.post<MandateResponse>(
      encode`/direct-debit/mandate?externalAccountNumber=${externalAccountNumber}`
    ).then((response) => {
      cache.delete("/direct-debit");
      return response;
    });
  },
  getLoadAllActiveMandates: () => {
    return API.get<MandateResponse[]>("/direct-debit/mandate/active");
  },
  getLoadAllRemovedMandates: () => {
    return API.get<MandateResponse[]>("/direct-debit/mandate/removed");
  },
  deleteMandate: (mandateId: MandateId) => {
    return API.delete(
      encode`/direct-debit/mandate?mandateId=${mandateId}`
    ).then((response) => {
      cache.delete("/direct-debit");
      return response;
    });
  },
  getAccountRestrictions: () => {
    return API.get<MonthlyDepositAccountRestrictionResponse[]>(
      "/direct-debit/monthly-deposit/accounts"
    );
  },
  getAccountRestriction: (externalAccountNumber: string) => {
    return API.get<MonthlyDepositAccountRestrictionResponse>(
      GET_ACOUNT_RESTRICTIONS_URL +
        encode`?externalAccountNumber=${externalAccountNumber}`
    );
  },
  postAddMonthlyDeposit: (
    createMonthlyDepositRequest: CreateMonthlyDepositRequest
  ) => {
    return API.post<MonthlyDepositResponse>(
      "/direct-debit/monthly-deposit",
      createMonthlyDepositRequest
    ).then((response) => {
      cache.delete("/direct-debit/monthly-deposit");
      cache.delete("/monthly-payments");
      return response;
    });
  },
  getValidExternalAccount: (externalAccountNumber: string) => {
    return API.get<ValidExternalAccountResponse>(
      encode`/direct-debit/monthly-deposit/accounts/valid?externalAccountNumber=${externalAccountNumber}`
    );
  },
  getPendingTransactions: () => {
    return API.get<PendingMonthlyDepositTransactionResponse[]>(
      `/direct-debit/monthly-deposit/pending`
    );
  },
};
