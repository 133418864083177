import { useContext, useState, VoidFunctionComponent } from "react";
import cx from "classnames";
import AnimateHeight from "react-animate-height";
import {
  Thread as ThreadType,
  MessagesAuthor,
  dataMessages,
  MessageSubject,
} from "../../data/dataMessages";
import { ThreadActions } from "./ThreadActions";
import { dynamicDateText, ThreadItem } from "./ThreadItem";
import { UserContext } from "../../context/UserContext";
import { defineMessages, useIntl } from "react-intl";
import { MessagesContext } from "../../context/MessagesContext";

import "./MessageList.scss";
import "./Thread.scss";
import { Signature } from "../signature/Signature";
import { HtmlEntityDecoder } from "./HtmlEntityDecoder";
import { NotificationsContext } from "../../context/NotificationsContext";
import { NotificationDot } from "../notificationDot/notificationDot";

interface Props {
  thread: ThreadType;
  reloadMessages?: () => void;
  setIsLoading?: () => void;
}

const intlMessages = defineMessages<MessageSubject>({
  [MessageSubject.ACCOUNTS_AND_ALLOCATION]: {
    id: "profile.messages.ACCOUNTS_AND_ALLOCATION",
    defaultMessage: "Accounts and allocation",
  },
  [MessageSubject.DEPOSITS_WITHDRAWALS]: {
    id: "profile.messages.DEPOSITS_WITHDRAWALS",
    defaultMessage: "Deposits and withdrawals",
  },
  [MessageSubject.FEEDBACK]: {
    id: "profile.messages.FEEDBACK",
    defaultMessage: "Feedback",
  },
  [MessageSubject.FEES]: {
    id: "profile.messages.FEES",
    defaultMessage: "Fees",
  },
  [MessageSubject.FUNDS]: {
    id: "profile.messages.FUNDS",
    defaultMessage: "Funds",
  },
  [MessageSubject.HOW_LYSA_WORK]: {
    id: "profile.messages.HOW_LYSA_WORK",
    defaultMessage: "How Lysa works",
  },
  [MessageSubject.OTHER]: {
    id: "profile.messages.OTHER",
    defaultMessage: "Other",
  },
  [MessageSubject.ROI]: {
    id: "profile.messages.ROI",
    defaultMessage: "Return on investment",
  },
  [MessageSubject.AML]: {
    id: "profile.messages.AML",
    defaultMessage: "Anti money laundering",
  },
  [MessageSubject.LIQUID_TRANSFER]: {
    id: "profile.messages.LIQUID_TRANSFER",
  },
  [MessageSubject.INSURANCE]: {
    id: "profile.messages.INSURANCE",
  },
  [MessageSubject.PENSION_MOVE]: {
    id: "profile.messages.PENSION_MOVE",
  },
  [MessageSubject.PENSION_MOVE_PRIVATE]: {
    id: "profile.messages.PENSION_MOVE",
  },
  [MessageSubject.ISK_TRANSFER]: {
    id: "profile.messages.ISK_TRANSFER",
  },
});

export const Thread: VoidFunctionComponent<Props> = ({
  thread,
  reloadMessages,
  setIsLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRead, setIsRead] = useState(thread.readByCustomer);
  const messagesContext = useContext(MessagesContext);
  const userContext = useContext(UserContext);
  const notificationsContext = useContext(NotificationsContext);
  const intl = useIntl();
  const user = userContext.state;

  const lastMessage = thread.messages[thread.messages.length - 1];

  return (
    <div className="thread">
      {!isRead && <NotificationDot />}
      <button
        className={cx("unstyled-button", "thread-button", {
          "is-read": isRead,
        })}
        data-id={thread.id}
        onClick={() => {
          if (!isRead) {
            const threads = [...messagesContext.state.messages];
            const readThread = threads.find((t) => t.id === thread.id);
            readThread && (readThread.readByCustomer = true);
            messagesContext.setState({ messages: threads });
            notificationsContext.setState({
              unreadMessages: dataMessages.getUnreadMessagesCount(threads),
            });
            if (!user.impersonator) {
              dataMessages.setRead(thread.id).catch(() => {
                const threads = [...messagesContext.state.messages];
                const readThread = threads.find((t) => t.id === thread.id);
                readThread && (readThread.readByCustomer = false);
                messagesContext.setState({ messages: threads });
                notificationsContext.setState({
                  unreadMessages: dataMessages.getUnreadMessagesCount(threads),
                });
              });
              setIsRead(true);
            }
          }

          setIsOpen(!isOpen);
        }}
      >
        <div className="all-but-date">
          <Signature
            user={
              lastMessage.author === MessagesAuthor.ADMIN
                ? lastMessage.author
                : user
            }
          />
          <div className="preview">
            <span className="subject">
              {intl.formatMessage(intlMessages[thread.subject])}
            </span>
            &nbsp;&ndash;&nbsp;
            <span className="short-message">
              <HtmlEntityDecoder text={thread.messages[0].message as string} />
            </span>
          </div>
        </div>
        <span>{dynamicDateText(thread.messages[0].date)}</span>
      </button>
      <AnimateHeight height={isOpen ? "auto" : 0} animateOpacity>
        <div className="thread">
          <ul>
            {thread.messages.map((message, idx) => {
              const hasAttachement = message.attachments.length > 0;
              const classes = cx("message", {
                "from-admin": message.author === MessagesAuthor.ADMIN,
                "from-customer": message.author === MessagesAuthor.CUSTOMER,
                "has-attachment": hasAttachement,
              });

              return (
                <li className={classes} key={`${thread.id}-${idx}`}>
                  <ThreadItem
                    message={message}
                    thread={thread}
                    isLast={idx === thread.messages.length - 1}
                  />
                </li>
              );
            })}
            <li>
              <ThreadActions
                thread={thread}
                user={user}
                reloadMessages={reloadMessages}
                setIsLoading={setIsLoading}
              />
            </li>
          </ul>
        </div>
      </AnimateHeight>
    </div>
  );
};
