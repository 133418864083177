import { Page, PageHeader } from "../Page";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import "./TransfersMenuPage.scss";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { getNavLink } from "../../hooks/useCountryUrls";
import { Link } from "react-router-dom";
import { WITHDRAWAL_PAGE_URL } from "../withdrawal/overview/WithdrawalPage";
import { HISTORIC_TRANSACTIONS_PAGE_URL } from "../historicTransactions/HistoricTransactionsPage";
import { MONTHLY_OVERVIEW_PAGE } from "../deposits/monthly/overview/MonthlyOverview";
import { INTERNAL_TRANSFER_PAGE_URL } from "../internalTransfer/InternalTransferPage";
import { ISK_TRANSFER_URL } from "../../countries/sweden/pages/iskTransfer/IskTransferStory";
import { DEPOSITS_OVERVIEW_URL } from "../deposits/overview/Recommendation";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../data/dataCustomerTracking";
import { Button } from "@lysaab/ui-2";
import { MONTHLY_INTERNAL_TRANSFER_PAGE_URL } from "../monthlyInternalTransfer/MonthlyInternalTransferStory";
import { TRANSFER_PENSIONS_URL } from "../../countries/sweden/pages/transferPensions/TransferPensionsStory";

export const TRANSFERS_MENU_PAGE_URL = "/transfers-menu";

const messages = defineMessages({
  deposits: {
    id: "transfer.menu.links.deposits",
  },
  withdrawals: {
    id: "transfer.menu.links.withdrawals",
  },
  monthly: {
    id: "transfer.menu.links.monthly",
  },
  transactions: {
    id: "transfer.menu.links.transactions",
  },
  internal: {
    id: "transfer.menu.links.transfer",
  },
  monthlyInternal: {
    id: "transfer.menu.links.monthlyInternal",
  },
  iskTransfer: {
    id: "transfer.menu.links.iskTransfer",
  },
  pensionTransfer: {
    id: "transfer.menu.links.pensionTransfer",
  },
  pensionTransferMove: {
    id: "transfer.menu.links.pensionTransferMove",
  },
});

export function TransfersMenuPage() {
  const intl = useIntl();

  return (
    <Page className="transfers-menu-page">
      <PageHeader>
        <h1>
          <FormattedMessage id="transfers.menu.header" />
        </h1>
      </PageHeader>

      <div className="transfers-menu-page-body">
        <div className="transfers-menu-page-links">
          <RouteAwareToggle path={getNavLink(DEPOSITS_OVERVIEW_URL)}>
            <Button
              component={Link}
              to={getNavLink(DEPOSITS_OVERVIEW_URL)}
              onClick={() => {
                dataCustomerTrackingService.postEvent({
                  domain: FeatureDomain.INVESTMENT_ACCOUNT,
                  eventName: "clickedTransferDepositButton",
                  subDomain: SubDomain.TRANSFERS,
                });
              }}
              label={intl.formatMessage(messages.deposits)}
            />
          </RouteAwareToggle>
          <RouteAwareToggle path={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
            <Button
              component={Link}
              to={getNavLink(MONTHLY_OVERVIEW_PAGE)}
              label={intl.formatMessage(messages.monthly)}
            />
          </RouteAwareToggle>
          <RouteAwareToggle path={getNavLink(WITHDRAWAL_PAGE_URL)}>
            <Button
              component={Link}
              to={getNavLink(WITHDRAWAL_PAGE_URL)}
              onClick={() => {
                dataCustomerTrackingService.postEvent({
                  domain: FeatureDomain.INVESTMENT_ACCOUNT,
                  eventName: "clickedTransferWithdrawalButton",
                  subDomain: SubDomain.TRANSFERS,
                });
              }}
              label={intl.formatMessage(messages.withdrawals)}
            />
          </RouteAwareToggle>
          <RouteAwareToggle path={getNavLink(HISTORIC_TRANSACTIONS_PAGE_URL)}>
            <Button
              component={Link}
              to={getNavLink(HISTORIC_TRANSACTIONS_PAGE_URL)}
              label={intl.formatMessage(messages.transactions)}
            />
          </RouteAwareToggle>
          <RouteAwareToggle path={getNavLink(INTERNAL_TRANSFER_PAGE_URL)}>
            <Button
              component={Link}
              to={getNavLink(INTERNAL_TRANSFER_PAGE_URL)}
              label={intl.formatMessage(messages.internal)}
            />
          </RouteAwareToggle>
          <RouteAwareToggle
            path={getNavLink(MONTHLY_INTERNAL_TRANSFER_PAGE_URL)}
          >
            <Button
              component={Link}
              to={getNavLink(MONTHLY_INTERNAL_TRANSFER_PAGE_URL)}
              label={intl.formatMessage(messages.monthlyInternal)}
            />
          </RouteAwareToggle>
          <RouteAwareToggle path={getNavLink(ISK_TRANSFER_URL)}>
            <Button
              component={Link}
              to={getNavLink(ISK_TRANSFER_URL)}
              label={intl.formatMessage(messages.iskTransfer)}
            />
          </RouteAwareToggle>
          <RouteAwareToggle path={getNavLink(TRANSFER_PENSIONS_URL)}>
            <Button
              component={Link}
              to={getNavLink(TRANSFER_PENSIONS_URL)}
              label={intl.formatMessage(messages.pensionTransferMove)}
              onClick={() => {
                dataCustomerTrackingService.postEvent({
                  domain: FeatureDomain.GENERAL,
                  eventName: "clickedTransferPensionButton",
                  subDomain: SubDomain.TRANSFERS,
                });
              }}
            />
          </RouteAwareToggle>
          <div className="padding-box"></div>
        </div>
      </div>
    </Page>
  );
}
