import * as React from "react";
import { Link } from "react-router-dom";
import { LysaLink, ServerError, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { ADD_EXTERNAL_MANUAL_URL } from "../../../addAccountManual/AddAccountManualStory";
import { ADD_EXTERNAL_AUTOGIRO_URL } from "../../../../../countries/sweden/pages/withdrawal/addAccountAutogiro/AddAccountAutogiroStory";
import { RouteAwareToggle } from "../../../../../components/route/RouteAwareToggle";

interface ErrorData {
  code: number;
  message: string;
  timestamp: string;
}

function isErrorData(value: unknown): value is ErrorData {
  return (
    value !== null &&
    typeof value === "object" &&
    "code" in value &&
    typeof value.code === "number" &&
    value.code !== undefined &&
    "message" in value &&
    typeof value.message === "string" &&
    value.message !== undefined &&
    "timestamp" in value &&
    typeof value.timestamp === "string" &&
    value.timestamp !== undefined
  );
}

enum ErrorMessages {
  VERIFY_OWNER_ERROR = "Could not verify owner",
  OWNER_NOT_MATCHING = "Owner not matching",
}

interface Props {
  error: ServerError<unknown>;
}

export const AccountSelectionError: React.VFC<Props> = ({ error }) => {
  if (
    isErrorData(error.data) &&
    error.data.message === ErrorMessages.OWNER_NOT_MATCHING
  ) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}>
          <TranslatedText
            id="sweden.widthdrawals.account.tink.story.error-owner-not-matching"
            values={{
              link: (text) => {
                return (
                  <LysaLink
                    component={Link}
                    to={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}
                  >
                    {text}
                  </LysaLink>
                );
              },
            }}
          />
        </RouteAwareToggle>
        <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_MANUAL_URL)}>
          <TranslatedText id="widthdrawals.account.tink.story.error-owner-not-matching" />
        </RouteAwareToggle>
      </Snackbar>
    );
  }

  if (
    isErrorData(error.data) &&
    error.data.message === ErrorMessages.VERIFY_OWNER_ERROR
  ) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}>
          <TranslatedText
            id="sweden.widthdrawals.account.tink.story.error-not-verified"
            values={{
              link: (text) => {
                return (
                  <LysaLink
                    component={Link}
                    to={getNavLink(ADD_EXTERNAL_AUTOGIRO_URL)}
                  >
                    {text}
                  </LysaLink>
                );
              },
            }}
          />
        </RouteAwareToggle>
        <RouteAwareToggle path={getNavLink(ADD_EXTERNAL_MANUAL_URL)}>
          <TranslatedText
            id="widthdrawals.account.tink.story.error-not-verified"
            values={{
              link: (text: React.ReactNode[]) => {
                return (
                  <LysaLink
                    component={Link}
                    to={getNavLink(ADD_EXTERNAL_MANUAL_URL)}
                  >
                    {text}
                  </LysaLink>
                );
              },
            }}
          />
        </RouteAwareToggle>
      </Snackbar>
    );
  }

  return (
    <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
      <TranslatedText id="widthdrawals.account.tink.story.error" />
    </Snackbar>
  );
};
