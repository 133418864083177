import { AUTOGIRO_DEPOSIT_PAGE_URL } from "../countries/sweden/pages/deposits/Autogiro/deposit/AutogiroDepositPage";
import { RequestData, SubDomain } from "../data/dataCustomerTracking";

import { FeatureDomain } from "../data/dataCustomerTracking";
import { MONTHLY_DEPOSITS_URL } from "../pages/deposits/monthly/create/MonthlyStory";
import { ADD_EXTERNAL_TINK_URL } from "../pages/withdrawal/addAccountTink/AddAccountTinkStory";

export const featureTrackingMissingBank = (pathname: string): RequestData => {
  if (pathname.includes(AUTOGIRO_DEPOSIT_PAGE_URL))
    return {
      domain: FeatureDomain.TRANSFERS,
      subDomain: SubDomain.DEPOSIT,
      eventName: "autogiroAddManually",
    };
  if (pathname.includes(MONTHLY_DEPOSITS_URL))
    return {
      domain: FeatureDomain.TRANSFERS,
      subDomain: SubDomain.MONTHLY,
      eventName: "monthlyAddManually",
    };
  if (pathname.includes(ADD_EXTERNAL_TINK_URL))
    return {
      domain: FeatureDomain.TRANSFERS,
      subDomain: SubDomain.WITHDRAWAL,
      eventName: "withdrawalAddManually",
    };
  return {
    domain: FeatureDomain.TRANSFERS,
    subDomain: SubDomain.TRANSFERS,
    eventName: "addManually",
  };
};

export const featureTrackingManualBackup = (pathname: string): RequestData => {
  if (pathname.includes(AUTOGIRO_DEPOSIT_PAGE_URL))
    return {
      domain: FeatureDomain.TRANSFERS,
      subDomain: SubDomain.DEPOSIT,
      eventName: "autogiroManualBackup",
    };
  if (pathname.includes(MONTHLY_DEPOSITS_URL))
    return {
      domain: FeatureDomain.TRANSFERS,
      subDomain: SubDomain.MONTHLY,
      eventName: "monthlyManualBackup",
    };
  if (pathname.includes(ADD_EXTERNAL_TINK_URL))
    return {
      domain: FeatureDomain.TRANSFERS,
      subDomain: SubDomain.WITHDRAWAL,
      eventName: "withdrawalManualBackup",
    };
  return {
    domain: FeatureDomain.TRANSFERS,
    subDomain: SubDomain.TRANSFERS,
    eventName: "manualBackup",
  };
};
