import { useReducer, createContext, FunctionComponent } from "react";
import * as React from "react";
import { TinkAccount, TinkAvailableBank } from "../../../data/dataTink";

export interface AddAccountTinkState {
  tinkBank?: TinkAvailableBank;
  tinkAccounts?: TinkAccount[];
  tinkSelectedAccount?: TinkAccount;
  accountVerificationReportId?: string;
}

export interface AddAccountTinkContextProps {
  state: AddAccountTinkState;
  setState: (newState: Partial<AddAccountTinkState>) => void;
}

export interface withAddAccountTinkProps {
  AddAccountTink: AddAccountTinkContextProps;
}

export const AddAccountTinkContext = createContext<AddAccountTinkContextProps>(
  {} as AddAccountTinkContextProps
);

function stateReducer(
  state: AddAccountTinkState,
  newState: Partial<AddAccountTinkState>
) {
  return { ...state, ...newState };
}

export const AddAccountTinkContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <AddAccountTinkContext.Provider value={{ state, setState }}>
      {children}
    </AddAccountTinkContext.Provider>
  );
};
