import { defineMessages } from "react-intl";

export const accountTableMessages = defineMessages({
  accountHeader: {
    id: "fees.feeTables.accountTable.accountHeader",
  },
  opened: {
    id: "fees.feeTables.accountTable.opened",
  },
  closed: {
    id: "fees.feeTables.accountTable.closed",
  },
  discretionaryFees: {
    id: "fees.feeTables.accountTable.discretionaryFees",
  },
  insuranceFees: {
    id: "fees.feeTables.accountTable.insuranceFees",
  },
  fundFees: {
    id: "fees.feeTables.accountTable.fundFees",
  },
  total: {
    id: "fees.feeTables.accountTable.total",
  },
});
