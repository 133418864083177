import { FC, PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  CardList,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import NumberFormat from "react-number-format";
import { getAccountTypeText } from "../../../../components/accountType/AccountType";
import { TranslatedText } from "../../../../components/TranslatedText";
import { AccountType, InvestmentAccount } from "../../../../data/dataAccounts";
import { CombinedAdviseAccount } from "../../../../data/dataInvestments";
import {
  Beneficiary,
  BeneficiaryType,
  dataLifePension,
  Insured,
  isStandardBeneficiaries,
  Pension,
  StandardBeneficiaryPriority,
} from "../../../../data/dataLifePension";
import { getInvestmentTypeText } from "../../../../texts/investmentTypeTexts";

import "./PensionInsuranceDetails.scss";

type State =
  | "LOADING"
  | "ERROR"
  | {
      details: Pension;
      beneficiaries: Beneficiary[];
      insured: Insured;
    };

interface Props {
  account: InvestmentAccount | undefined;
  advise: CombinedAdviseAccount | undefined;
}

export const PensionInsuranceDetails: FC<Props> = ({ account, advise }) => {
  const intl = useIntl();
  const [state, setState] = useState<State>("LOADING");

  useEffect(() => {
    if (!account?.accountId) {
      return;
    }

    Promise.all([
      dataLifePension.getPensionDetails(account.accountId),
      dataLifePension.getBeneficiaries(account.accountId),
      dataLifePension.getInsured(account.accountId),
    ])
      .then(([details, beneficiaries, insured]) => {
        setState({
          details,
          beneficiaries,
          insured,
        });
      })
      .catch(() => {
        setState("ERROR");
      });
  }, [account?.accountId]);

  if (state === "LOADING" || !account || !advise) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }

  if (state === "ERROR") {
    return (
      <Wrapper>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon textAlign="left">
          <TranslatedText id="account.pensionInsuranceDetails.error" />
        </Snackbar>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <dl className="item-list">
        <DescriptionItem
          term={<TranslatedText id="account.pensionInsuranceDetails.product" />}
          details={
            account.type !== AccountType.LYSA_PPF &&
            state.details.privateProperty
              ? `${intl.formatMessage({
                  id: "account.pensionInsuranceDetails.product.private",
                })} ${getAccountTypeText(
                  account,
                  true,
                  intl
                ).toLocaleLowerCase()}`
              : getAccountTypeText(account, true, intl)
          }
        />
        <DescriptionItem
          term={<TranslatedText id="account.pensionInsuranceDetails.insured" />}
          details={state.insured.name}
        />
        <DescriptionItem
          term={<TranslatedText id="account.pensionInsuranceDetails.tin" />}
          details={
            state.insured.tin.length === 12 ? (
              <NumberFormat
                displayType="text"
                format={"########-####"}
                value={state.insured.tin}
              />
            ) : (
              state.insured.tin
            )
          }
        />
        {!state.details.privateProperty && (
          <DescriptionItem
            term={
              <TranslatedText id="account.pensionInsuranceDetails.employer" />
            }
            details={state.details.employer ?? "-"}
          />
        )}
        <DescriptionItem
          term={
            <TranslatedText id="account.pensionInsuranceDetails.withdrawalStart" />
          }
          details={
            <TranslatedText
              id="account.pensionInsuranceDetails.withdrawalStart.age"
              values={{ age: state.details.withdrawalStartAge }}
            />
          }
        />
        <DescriptionItem
          term={
            <TranslatedText id="account.pensionInsuranceDetails.withdrawalLength" />
          }
          details={
            state.details.lifeLong ? (
              <TranslatedText id="account.pensionInsuranceDetails.withdrawalLength.life-long" />
            ) : (
              <TranslatedText
                id="account.pensionInsuranceDetails.withdrawalLength.years"
                values={{ years: state.details.withdrawalLength }}
              />
            )
          }
        />
        <DescriptionItem
          term={
            <TranslatedText id="account.pensionInsuranceDetails.investmentType" />
          }
          details={getInvestmentTypeText(intl, advise.investmentType)}
        />
        <DescriptionItem
          term={<TranslatedText id="account.pensionInsuranceDetails.created" />}
          details={intl.formatDate(account.created)}
        />
        <DescriptionItem
          term={
            <TranslatedText id="account.pensionInsuranceDetails.repayment" />
          }
          details={
            state.details.repaymentProtection ? (
              <TranslatedText id="account.pensionInsuranceDetails.repayment.yes" />
            ) : (
              <TranslatedText id="account.pensionInsuranceDetails.repayment.no" />
            )
          }
        />
        {state.details.repaymentProtection && (
          <DescriptionItem
            sideBySide={false}
            term={
              <TranslatedText id="account.pensionInsuranceDetails.beneficiaries" />
            }
            details={
              isStandardBeneficiaries(state.beneficiaries)
                ? state.beneficiaries.map((beneficiary, index) => (
                    <div key={index}>
                      {`${intl.formatMessage(
                        beneficiaryPriorityMessages[beneficiary.priority]
                      )}: ${intl.formatMessage(
                        beneficiaryTypeMessages[beneficiary.type]
                      )}`}
                    </div>
                  ))
                : intl.formatMessage(
                    beneficiaryTypeMessages[BeneficiaryType.PRIVATE]
                  )
            }
          />
        )}
        <DescriptionItem
          sideBySide={false}
          term={
            <TranslatedText id="account.pensionInsuranceDetails.insurance-number" />
          }
          details={account.accountId}
        />
      </dl>
    </Wrapper>
  );
};

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <section className="PensionInsuranceDetails account-page-pension-insurance-details">
      <Typography className="header" type="h2">
        <TranslatedText id="account.pensionInsuranceDetails.header" />
      </Typography>
      <CardList>
        <div className="account-page-card-body">{children}</div>
      </CardList>
    </section>
  );
};

const DescriptionItem: FC<{
  term: ReactNode;
  details: ReactNode;
  sideBySide?: boolean;
}> = ({ term, details, sideBySide = true }) => {
  return (
    <div className={sideBySide ? "side-by-side" : ""}>
      <Typography type="label" component="dt">
        {term}
      </Typography>
      <Typography component="dd">{details}</Typography>
    </div>
  );
};

const beneficiaryPriorityMessages = defineMessages<StandardBeneficiaryPriority>(
  {
    1: {
      id: "account.pensionInsuranceDetails.beneficiaries.priority.first",
    },
    2: {
      id: "account.pensionInsuranceDetails.beneficiaries.priority.second",
    },
  }
);

const beneficiaryTypeMessages = defineMessages<
  Exclude<BeneficiaryType, BeneficiaryType.NONE>
>({
  [BeneficiaryType.SPOUSE]: {
    id: "account.pensionInsuranceDetails.beneficiaries.SPOUSE",
  },
  [BeneficiaryType.CHILD]: {
    id: "account.pensionInsuranceDetails.beneficiaries.CHILD",
  },
  [BeneficiaryType.PRIVATE]: {
    id: "account.pensionInsuranceDetails.beneficiaries.PRIVATE",
  },
});
