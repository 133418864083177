import { API } from "@lysaab/ui-2";

export enum Bank {
  SKANDIABANKEN = "SKANDIABANKEN",
  SEB = "SEB",
  NORDEA = "NORDEA",
  HANDELSBANKEN = "HANDELSBANKEN",
  SWEDBANK = "SWEDBANK",
  LANSFORSAKRINGAR = "LANSFORSAKRINGAR",
  DANSKE_BANK = "DANSKE_BANK",
  ICA = "ICA",
  OP = "OP",
  SPANKKI = "S-PANKKI",
  SAASTOPANKKI = "SAASTOPANKKI",
  AKTIA = "AKTIA",
  POP_PANKKI = "POP_PANKKI",
  OMA_SAASTOPANKKI = "OMA_SAASTOPANKKI",
  ALANDSBANKEN = "ALANDSBANKEN",
  N26 = "N26",
  JYSKE = "JYSKE",
  SYDBANK = "SYDBANK",
}

export type TinkBankId = string & { readonly isTinkBankId: true };
export type accountVerificationReportId = string & {
  readonly isAccountVerificationReportId: true;
};

export interface TinkAvailableBank {
  id: TinkBankId;
  name: string;
  iconUrl: string;
  accountHolderVerification: boolean;
}

export interface TinkSession {
  sessionId: string;
  clientId: string;
  providerName: string;
}

export interface TinkAccount {
  iban: string;
  accountNumber: string;
  name: string;
}

export const dataTink = {
  getAllBanks: () => {
    return API.get<TinkAvailableBank[]>("/tink/bank");
  },
  createSession: (bankId: TinkBankId) => {
    return API.post<TinkSession>("/tink/session", { bankId });
  },
  getAccounts: (accountVerificationReportId: accountVerificationReportId) => {
    return API.get<{ accounts: TinkAccount[] }>(
      "/tink/account?accountVerificationReportId=" + accountVerificationReportId
    );
  },
};
