import { FunctionComponent, useEffect } from "react";

import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { Help } from "../components/help/Help";
import { Move, useTransfer } from "../TransferContext";
import {
  dataLifePensionMove,
  PensionMoveSigningOptions,
  PensionMoveStatus,
} from "../../../../../data/dataLifePensionMove";
import { Ingress } from "../components/ingress/Ingress";
import { FAQ, FaqItemProps } from "../components/faq/FAQ";
import { MoveSteps } from "../components/moveSteps/MoveSteps";
import { AccountType } from "../../../../../data/dataAccounts";
import { Button, Spinner, Success, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import "./Done.scss";
import { useMutation, useQuery } from "@tanstack/react-query";
import { NotMovedPensionsSurveyParams } from "../../../../../components/notMovedPensionSurvey/NotMovedPensionsSurvey";

// TODO: Translate these.

const faqItems: FaqItemProps[] = [
  {
    title: "Hur lång tid tar en flytt?",
    content:
      "Att flytta en pension tar normalt cirka tre månader, beroende på vilket bolag du flyttar ifrån och hur snabbt det går att samla in alla nödvändiga signaturer.",
  },
  {
    title: "Vad händer med mina pensionstillgångar under flytten?",
    content:
      "Under flyttprocessen är dina pengar fortsatt investerade och du ligger därför inte utanför marknaden mer än några bankdagar.",
  },
  {
    title: "Vad händer när pensionen har flyttats till Lysa?",
    content:
      "När pensionen har flyttats investeras dina pengar automatiskt enligt din valda målfördelning. Du kan sedan följa och göra ändringar i ditt pensionssparande både i appen och på hemsidan.",
  },
];

const usePensionMoveSummary = ({
  caseId,
  initialData,
}: {
  caseId: string;
  initialData: Move[];
}) => {
  return useQuery({
    queryKey: ["pensionMoveSummary", caseId],
    queryFn: () =>
      dataLifePensionMove.getMove(caseId).then((res): Move[] => res.moves),
    // If we supply initialData we should not need a loading state,
    // data from the context will be rendered initially and fresh data fetched
    // in the background.
    initialData,
    // To mark the initialData as stale, so that it will be refetched immediately
    staleTime: 0,
  });
};

const usePensionCollectionData = () => {
  return useQuery({
    queryKey: ["pensionCollectionData"],
    queryFn: () => dataLifePensionMove.getPensionData(),
  });
};

const useNotMovedWorth = (moved: Move[]) => {
  const {
    data: pensionCollectionData,
    isPending,
    isError,
  } = usePensionCollectionData();

  if (isPending) {
    return undefined;
  }

  if (isError) {
    return 0;
  }

  const notMoved =
    pensionCollectionData.movability.MOVABLE?.filter(
      (movable) =>
        !moved.find(
          (m) =>
            m.insuranceNumber === movable.insuranceNumber &&
            m.institute === movable.insuranceCompany
        )
    ) || [];

  return notMoved.reduce(
    (sum, pensionData) => pensionData.currentWorth + sum,
    0
  );
};

const useSendEmailConfirmation = () => {
  return useMutation({
    mutationFn: (caseId: string) =>
      dataLifePensionMove.sendEmailConfirmation(caseId),
    onError: (err) => {
      console.error(`Failed to send request for email confirmation.`, err);
    },
  });
};

const movesHasManualSigning = (moves: Move[]) =>
  moves.some((move) => move.signing === PensionMoveSigningOptions.MANUAL);

const uniqueMoveAccountTypes = (moves: Move[]): AccountType[] =>
  Array.from(
    moves.reduce(
      (types, move) => (move.type ? types.add(move.type) : types),
      new Set<AccountType>()
    )
  );

const uniquePensionMoveStatuses = (moves: Move[]): PensionMoveStatus[] =>
  Array.from(
    moves.reduce(
      (states, move) => (move.state ? states.add(move.state) : states),
      new Set<PensionMoveStatus>()
    )
  );

const useCaseId = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathnameParts = pathname.split("/");
  const caseId = pathnameParts[pathnameParts.length - 1];
  return caseId;
};

interface Props {
  setNotMovedPensionsSurveyParams: (
    params: NotMovedPensionsSurveyParams
  ) => void;
  next: () => void;
}

export const Done: FunctionComponent<Props> = ({
  setNotMovedPensionsSurveyParams,
  next,
}) => {
  const intl = useIntl();
  const [transfer] = useTransfer();
  const caseId = useCaseId();
  const { mutate: sendEmail } = useSendEmailConfirmation();
  const { data: moves, isPending } = usePensionMoveSummary({
    caseId,
    initialData: transfer.moves,
  });
  const notMovedWorth = useNotMovedWorth(moves);

  useEffect(() => {
    const shouldShowSurvey =
      typeof notMovedWorth !== "undefined" && notMovedWorth > 0;
    if (shouldShowSurvey) {
      setNotMovedPensionsSurveyParams({
        showSurvey: true,
        worth: notMovedWorth,
        caseId,
      });
    }
  }, [caseId, notMovedWorth, setNotMovedPensionsSurveyParams]);

  useEffect(() => {
    if (caseId) {
      sendEmail(caseId);
    }
  }, [caseId, sendEmail]);

  return (
    <article className="transfer-pension-done">
      <section className="success">
        <Success />
      </section>
      {isPending ? (
        <Spinner />
      ) : (
        <Heading hasManualSigning={movesHasManualSigning(moves)} />
      )}
      <hr className="divider" />
      <MoveSteps
        accountTypes={uniqueMoveAccountTypes(moves)}
        pensionMoveStatuses={uniquePensionMoveStatuses(moves)}
      />

      <FAQ faqItems={faqItems} />
      <Help />

      <Button
        className="cta"
        block
        variant="primary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.done.button",
        })}
        onClick={next}
      />
    </article>
  );
};

const Heading: FunctionComponent<{ hasManualSigning: boolean }> = ({
  hasManualSigning,
}) => {
  return hasManualSigning ? (
    <>
      <Typography type="h2" className="header">
        <TranslatedText id="sweden.transfer-pension.done.manual.header" />
      </Typography>
      <Ingress>
        <TranslatedText id="sweden.transfer-pension.done.manual.ingress" />
      </Ingress>
    </>
  ) : (
    <>
      <Typography type="h2" className="header">
        <TranslatedText id="sweden.transfer-pension.done.header" />
      </Typography>
      <Ingress>
        <TranslatedText id="sweden.transfer-pension.done.ingress" />
      </Ingress>
    </>
  );
};
