import { Information } from "@lysaab/ui-2/components/icons/Information";
import { useIntl } from "react-intl";
import { TranslatedText } from "../TranslatedText";

interface Props {
  matches: number;
}

export const RefineSearchCard: React.VFC<Props> = ({ matches }) => {
  const intl = useIntl();

  return (
    <div className="transparent-button card-list-item missing-bank-link refine-card">
      <Information size={36} />
      <span className="refine-card-text">
        <TranslatedText
          id="tink.bank-selection.refine"
          values={{ matches: intl.formatNumber(matches) }}
        />
      </span>
    </div>
  );
};
