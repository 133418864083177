import { API, encode } from "@lysaab/ui-2";

export interface Totp {
  password: string;
  expires: string;
}

export const dataTotp = {
  getTOTP() {
    return API.get<Totp>(encode`/phone/totp/generate`, true);
  },
};
