import { Typography } from "@lysaab/ui-2";
import { FunctionComponent } from "react";
import { Currency, useCurrency } from "../../../context/LocalizationContext";
import { TranslatedText } from "../../../components/TranslatedText";

import "./FeesDisclaimer.scss";

export const FeeDisclaimer: FunctionComponent = () => {
  const currency = useCurrency();

  return (
    <section className="fees-disclaimer">
      <Typography type="body">
        <TranslatedText id="fees.fees-disclaimer.intro" />
      </Typography>
      <Typography type="body">
        <TranslatedText
          id="fees.fees-disclaimer.case"
          values={{
            currency: () => {
              if (currency === Currency.DKK) {
                return "kr";
              } else if (currency === Currency.EUR) {
                return "€";
              } else {
                return "kr";
              }
            },
          }}
        />
      </Typography>
      <ul className="list">
        <li>
          <Typography type="body">
            <TranslatedText
              id="fees.fees-disclaimer.alternative-one"
              values={{
                currency: () => {
                  if (currency === Currency.DKK) {
                    return "kr";
                  } else if (currency === Currency.EUR) {
                    return "€";
                  } else {
                    return "kr";
                  }
                },
              }}
            />
          </Typography>
        </li>
        <li>
          <Typography type="body">
            <TranslatedText
              id="fees.fees-disclaimer.alternative-two"
              values={{
                currency: () => {
                  if (currency === Currency.DKK) {
                    return "kr";
                  } else if (currency === Currency.EUR) {
                    return "€";
                  } else {
                    return "kr";
                  }
                },
              }}
            />
          </Typography>
        </li>
      </ul>
    </section>
  );
};
