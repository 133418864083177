import { Fragment, FC } from "react";
import { FormattedMessage } from "react-intl";
import "./Description.scss";
import { Typography } from "@lysaab/ui-2";

interface Props {
  isRecommended?: boolean;
  isNew?: boolean;
  text: string | JSX.Element;
}

export const Description: FC<Props> = ({
  text,
  isRecommended = false,
  isNew = false,
}) => {
  return (
    <Typography type="body-small" component="span">
      <span className="deposit-method-description">
        {isRecommended && (
          <Fragment>
            <span className="recommendation">
              <FormattedMessage id="deposit-recommendation.label.recommended" />
            </span>{" "}
          </Fragment>
        )}
        {isNew && (
          <Fragment>
            <span className="recommendation">
              <FormattedMessage id="deposit-recommendation.label.new" />
            </span>{" "}
          </Fragment>
        )}
        {text}
      </span>
    </Typography>
  );
};
