import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { Modal } from "../modal/Modal";
import {
  Button,
  RadioGroupSimpleCard,
  TextInput,
  Typography,
} from "@lysaab/ui-2";
import { useMutation } from "@tanstack/react-query";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../data/dataCustomerTracking";
import { TranslatedText } from "../TranslatedText";
import { EventTracker } from "../eventTracker/EventTracker";

export interface NotMovedPensionsSurveyParams {
  showSurvey: boolean;
  worth: number;
  caseId: string;
}

const useSurveyParams = () => {
  const { state } = useLocation<
    | undefined
    | {
        notMovedPensionsSurveyParams?: NotMovedPensionsSurveyParams;
      }
  >();
  return {
    showSurvey: state?.notMovedPensionsSurveyParams?.showSurvey === true,
    worth: state?.notMovedPensionsSurveyParams?.worth,
    caseId: state?.notMovedPensionsSurveyParams?.caseId,
  };
};

const useShouldShowSurvey = () => {
  const { showSurvey, caseId } = useSurveyParams();
  // use sessionStorage to avoid showing the survey again if you closed it and then end up on the page by back-button.
  // Back-button will restore the history state that was used.
  const hasShownSurvey =
    sessionStorage.getItem(`not-moved-pensions-survey-shown-${caseId}`) ===
    "true";
  const markSurveySeen = useCallback(() => {
    sessionStorage.setItem(`not-moved-pensions-survey-shown-${caseId}`, "true");
  }, [caseId]);
  return { showSurvey: showSurvey && !hasShownSurvey, markSurveySeen };
};

const useModalState = () => {
  const { showSurvey, markSurveySeen } = useShouldShowSurvey();
  const [modalOpened, setModalOpened] = useState(Boolean(showSurvey));

  const closeModal = useCallback(() => {
    markSurveySeen();
    setModalOpened(false);
  }, [markSurveySeen]);

  return { modalOpened, closeModal };
};

const useTrackSurveyShown = ({
  worth,
  surveyOpened,
}: {
  worth: number | undefined;
  surveyOpened: boolean;
}) => {
  useEffect(() => {
    if (surveyOpened) {
      EventTracker.track({
        event: TrackerEvent.TRANSFER_PENSION_NOT_MOVED_SURVEY,
        message: `Showed survey for not moved pensions with worth of ${worth}`,
      });
    }
    // We do not want worth as dependency, we really only want to trigger this once per mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyOpened]);
};

export const NotMovedPensionsSurvey: FunctionComponent = () => {
  const [surveyAnswer, setSurveyAnswer] = useState<SurverResponse>({
    answer: undefined,
    freeText: "",
  });
  const { modalOpened, closeModal } = useModalState();
  const { worth, caseId } = useSurveyParams();
  const { mutate: sendSurveyAnswer } = useSendSurveyAnswer();
  const intl = useIntl();
  useTrackSurveyShown({ worth, surveyOpened: modalOpened });

  const onDone = () => {
    sendSurveyAnswer({
      answer: surveyAnswer.answer,
      freeText: surveyAnswer.freeText,
      worth,
      caseId,
    });
    closeModal();
  };

  return (
    <Modal
      header={intl.formatMessage({
        id: "sweden.transfer-pension.done.survey-header",
      })}
      showModal={modalOpened}
      onClose={onDone}
      closeOnOverlayClick
      hideScrollTopButton
    >
      <Survey value={surveyAnswer} onChange={setSurveyAnswer} />
      <Button
        className="cta"
        block
        variant="primary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.done.button",
        })}
        onClick={onDone}
      />
    </Modal>
  );
};

const useSendSurveyAnswer = () =>
  useMutation({
    mutationFn: ({
      answer,
      freeText,
      worth,
      caseId,
    }: {
      // Allow undefined for answer if the user filled in freeText
      // without selecting an alternative.
      answer: SurveyAlternative | undefined;
      freeText: string;
      worth: number | undefined;
      caseId: string | undefined;
    }) =>
      dataCustomerTrackingService.postEvent({
        eventName: "movedButRemainingPensionsSurvey",
        domain: FeatureDomain.PENSIONS,
        subDomain: SubDomain.MOVE,
        payload: {
          answer: answer || null,
          freeText: freeText || null,
          worth: worth ?? null,
          caseId: caseId || null,
        },
      }),
  });

enum SurveyAlternative {
  TESTING_FIRST = "TESTING_FIRST",
  PERKS_FROM_INSTITUTE = "PERKS_FROM_INSTITUTE",
  SELF_MANAGE = "SELF_MANAGE",
  OTHER = "OTHER",
}

type SurverResponse = {
  answer: SurveyAlternative | undefined;
  freeText: string;
};

interface SurveyProps {
  value: SurverResponse;
  onChange: ({ answer, freeText }: SurverResponse) => void;
}

const Survey: FunctionComponent<SurveyProps> = ({ value, onChange }) => {
  const intl = useIntl();
  const alternatives = useMemo(
    () => [
      {
        text: intl.formatMessage({
          id: "sweden.transfer-pension.done.survey.testing-first",
        }),
        value: SurveyAlternative.TESTING_FIRST,
      },
      {
        text: intl.formatMessage({
          id: "sweden.transfer-pension.done.survey.perks-from-institute",
        }),
        value: SurveyAlternative.PERKS_FROM_INSTITUTE,
      },
      {
        text: intl.formatMessage({
          id: "sweden.transfer-pension.done.survey.self-manage",
        }),
        value: SurveyAlternative.SELF_MANAGE,
      },
      {
        text: intl.formatMessage({
          id: "sweden.transfer-pension.done.survey.other",
        }),
        value: SurveyAlternative.OTHER,
      },
    ],
    [intl]
  );

  return (
    <section className="survey">
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.done.survey-body" />
      </Typography>
      <RadioGroupSimpleCard<SurveyAlternative>
        legend=""
        alternatives={alternatives}
        value={value.answer}
        onChange={(alternative) => onChange({ ...value, answer: alternative })}
      />
      <TextInput
        label={intl.formatMessage({
          id: "sweden.transfer-pension.done.survey-other-free-text-label",
        })}
        value={value.freeText}
        onChange={(freeText) => onChange({ ...value, freeText })}
      />
    </section>
  );
};
