import { Fragment, useEffect, useState, VoidFunctionComponent } from "react";
import {
  HistoricFeeAccountResponse,
  HistoricFeeClosedAccountResponse,
} from "../../../../../data/dataFees";
import { DiscretionaryFeesTable } from "./discretionaryFeesTable/DiscretionaryFeesTable";
import { FundFeesTable } from "./fundFeesTable/FundFeesTable";
import { ShowMoreButton } from "../../components/showMoreButton/ShowMoreButton";
import { defineMessages, useIntl } from "react-intl";
import { isClosedAccount } from "../AccountTable";
import { LysaTableTextCell } from "../../../../../components/lysaTableTextCell/LysaTableTextCell";
import { accountTableMessages } from "../AccountTableMessages";
import { dataAccounts } from "../../../../../data/dataAccounts";
import { InvestmentAccount } from "../../../../../data/dataAccounts";
import { CostBodyCell } from "../../components/costBodyCell/CostBodyCell";
import { FeeBodyCell } from "../../components/feeBodyCell/FeeBodyCell";
import { InsuranceFeesTable } from "./insuranceFeesTable/InsuranceFeeTable";

const messages = defineMessages({
  noFeePaid: {
    id: "fees.feeTables.accountTables.accountRow.noFeePaid",
  },
  openButtonText: {
    id: "fees.feeTables.accountTables.accountRow.openButtonText",
  },
  closeButtonText: {
    id: "fees.feeTables.accountTables.accountRow.closeButtonText",
  },
});
interface Props {
  accountData: HistoricFeeAccountResponse | HistoricFeeClosedAccountResponse;
}

enum DataView {
  FUND = "FUND",
  DISCRETIONARY = "DISCRETIONARY",
  INSURANCE = "INSURANCE",
  NONE = "NONE",
}

const NBR_OF_CLOSED_COLUMNS = 6;
const NBR_OF_ACTIVE_COLUMNS = 4;

export const AccountRow: VoidFunctionComponent<Props> = ({ accountData }) => {
  const intl = useIntl();
  const [accountName, setAccountName] = useState("");
  const [accounts, setAccounts] = useState<InvestmentAccount[]>();
  const [dataView, setDataView] = useState<DataView>(DataView.NONE);

  useEffect(() => {
    dataAccounts.getAccounts().then((response) => {
      setAccounts(response);
    });
  }, []);

  useEffect(() => {
    if (isClosedAccount(accountData)) {
      setAccountName(accountData.accountName);
    } else {
      setAccountName(
        accounts?.find((account) => {
          return account.accountId === accountData.accountId;
        })?.name || accountData.accountId
      );
    }
  }, [accountData, accounts]);

  const toggleDataView = (targetView: DataView) => {
    setDataView((prev) => (prev === targetView ? DataView.NONE : targetView));
  };

  return (
    <Fragment>
      <tr>
        <LysaTableTextCell
          value={accountName}
          label={intl.formatMessage(accountTableMessages.accountHeader)}
        />
        {isClosedAccount(accountData) && (
          <Fragment>
            <LysaTableTextCell
              value={intl.formatDate(accountData.created)}
              label={intl.formatMessage(accountTableMessages.opened)}
            />
            <LysaTableTextCell
              value={intl.formatDate(accountData.closed)}
              label={intl.formatMessage(accountTableMessages.closed)}
            />
          </Fragment>
        )}
        {accountData.total > 0 ? (
          <Fragment>
            <LysaTableTextCell
              label={intl.formatMessage(accountTableMessages.discretionaryFees)}
            >
              <ShowMoreButton
                isOpen={dataView === DataView.DISCRETIONARY}
                onClick={() => toggleDataView(DataView.DISCRETIONARY)}
                openText={intl.formatMessage(messages.openButtonText)}
                closedText={intl.formatMessage(messages.closeButtonText)}
              />
            </LysaTableTextCell>
            {accountData.insurance && (
              <LysaTableTextCell
                label={intl.formatMessage(accountTableMessages.insuranceFees)}
              >
                <ShowMoreButton
                  isOpen={dataView === DataView.INSURANCE}
                  onClick={() => toggleDataView(DataView.INSURANCE)}
                  openText={intl.formatMessage(messages.openButtonText)}
                  closedText={intl.formatMessage(messages.closeButtonText)}
                />
              </LysaTableTextCell>
            )}
            <LysaTableTextCell
              label={intl.formatMessage(accountTableMessages.fundFees)}
            >
              <ShowMoreButton
                isOpen={dataView === DataView.FUND}
                onClick={() => toggleDataView(DataView.FUND)}
                openText={intl.formatMessage(messages.openButtonText)}
                closedText={intl.formatMessage(messages.closeButtonText)}
              />
            </LysaTableTextCell>
          </Fragment>
        ) : (
          <Fragment>
            <LysaTableTextCell
              value={intl.formatMessage(messages.noFeePaid)}
              label={intl.formatMessage(accountTableMessages.discretionaryFees)}
            />
            {accountData.insurance && (
              <LysaTableTextCell
                value={intl.formatMessage(messages.noFeePaid)}
                label={intl.formatMessage(accountTableMessages.insuranceFees)}
              />
            )}
            <LysaTableTextCell
              value={intl.formatMessage(messages.noFeePaid)}
              label={intl.formatMessage(accountTableMessages.fundFees)}
            />
          </Fragment>
        )}
        <CostBodyCell
          value={accountData.averageTotalCost}
          label={intl.formatMessage(accountTableMessages.total)}
        />
        <FeeBodyCell
          value={accountData.total}
          label={intl.formatMessage(accountTableMessages.total)}
        />
      </tr>
      {dataView === DataView.FUND && (
        <tr>
          <FundFeesTable
            accountData={accountData.fund}
            isOpen={dataView === DataView.FUND}
            columns={
              isClosedAccount(accountData)
                ? NBR_OF_CLOSED_COLUMNS
                : NBR_OF_ACTIVE_COLUMNS
            }
          />
        </tr>
      )}
      {dataView === DataView.DISCRETIONARY && (
        <tr>
          <DiscretionaryFeesTable
            accountData={accountData.discretionary}
            isOpen={dataView === DataView.DISCRETIONARY}
            columns={
              isClosedAccount(accountData)
                ? NBR_OF_CLOSED_COLUMNS
                : NBR_OF_ACTIVE_COLUMNS
            }
          />
        </tr>
      )}
      {dataView === DataView.INSURANCE && accountData.insurance && (
        <tr>
          <InsuranceFeesTable
            accountData={accountData.insurance}
            isOpen={dataView === DataView.INSURANCE}
            columns={
              isClosedAccount(accountData)
                ? NBR_OF_CLOSED_COLUMNS
                : NBR_OF_ACTIVE_COLUMNS
            }
          />
        </tr>
      )}
    </Fragment>
  );
};
