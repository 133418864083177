import { FC } from "react";

interface Props {
  size?: number;
}

export const PensionIcon: FC<Props> = ({ size = 40 }) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2494_1345)">
        <circle cx="20" cy="20" r="20" fill="#F1F1F1" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9578 10.7127C12.7992 10.1837 12.2417 9.88348 11.7127 10.0422C11.1837 10.2009 10.8835 10.7584 11.0422 11.2874L13.6146 19.862C13.8979 20.8062 14.617 21.529 15.5123 21.8371L13.4763 26.2484C12.838 25.8777 12.2245 25.4481 11.6437 24.9596C11.2211 24.6041 10.5902 24.6585 10.2347 25.0812C9.87923 25.5038 9.93368 26.1346 10.3563 26.4901C15.9201 31.1699 24.0798 31.1699 29.6436 26.4901C30.0663 26.1346 30.1207 25.5038 29.7652 25.0812C29.4097 24.6585 28.7789 24.6041 28.3563 24.9596C27.7755 25.448 27.162 25.8776 26.5237 26.2484L24.5629 22H26C26.5523 22 27 21.5523 27 21C27 20.4477 26.5523 20 26 20L22.362 20L22.3602 20H17.6399L16.4881 20C16.0465 20 15.6572 19.7103 15.5303 19.2874L12.9578 10.7127ZM17.6399 22H22.3602L24.7214 27.1159C21.6916 28.2946 18.3085 28.2946 15.2787 27.1159L17.6399 22Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2494_1345">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
