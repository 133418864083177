import { useEffect, useRef, useState } from "react";
import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { useMonthlyContext } from "../../MonthlyContext";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { BASE_ROUTES, MONTHLY_DEPOSITS_URL } from "../../MonthlyStory";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { dataWithdrawals } from "../../../../../../data/dataWithdrawals";
import { TrackerEvent } from "../../../../../../data/dataCustomerTracking";
import { TinkAccount } from "../../../../../../data/dataTink";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  accountLabel: {
    id: "deposits.monthly.story.account-selection.account.label",
  },
  accountRequired: {
    id: "deposits.monthly.story.account-selection.account.required",
  },
});

const getAccountLabel = (account: TinkAccount) => {
  if (account.name) {
    return `${account.name} - ${account.iban}`;
  }

  return account.iban;
};

export function AccountSelection({ next }: Props) {
  const monthlyContext = useMonthlyContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const selectedTinkAccount = monthlyContext.state.tinkSelectedAccount;
  const accountVerificationReportId =
    monthlyContext.state.accountVerificationReportId;

  const formRef = useRef<LysaFormRef>();

  useEffect(() => {
    if (!monthlyContext.state.tinkAccounts) {
      history.push(getNavLink(MONTHLY_DEPOSITS_URL));
    }
  }, [history, monthlyContext.state.tinkAccounts]);

  if (!monthlyContext.state.tinkAccounts || isLoading) {
    return <Spinner />;
  }

  const sortedTinkAccounts = monthlyContext.state.tinkAccounts.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  if (sortedTinkAccounts.length === 0)
    return (
      <div>
        <h2>
          <TranslatedText id="deposits.monthly.story.account-selection.no-accounts.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <p>
            <TranslatedText id="deposits.monthly.story.account-selection.no-accounts.text" />
          </p>
        </Snackbar>
        <Button
          block
          onClick={() => history.push(getNavLink(BASE_ROUTES.BANK_SELECTION))}
          label={
            <TranslatedText id="deposits.monthly.story.account-selection.no-accounts.button" />
          }
        />
      </div>
    );

  return (
    <div>
      <h2>
        <TranslatedText id="deposits.monthly.story.account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            typeof selectedTinkAccount === "undefined" ||
            !accountVerificationReportId
          ) {
            return;
          }
          EventTracker.track({
            event: TrackerEvent.ADD_MONTHLY_TINK_ACCOUNT,
            message: selectedTinkAccount.iban,
          });
          setIsLoading(true);
          dataWithdrawals
            .addWithdrawalAccountTink(
              selectedTinkAccount.iban,
              accountVerificationReportId
            )
            .then(next)
            .catch(() => {
              setIsError(true);
            })
            .finally(() => setIsLoading(false));
        }}
      >
        <Card>
          {isError && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="deposits.monthly.story.account-selection.error" />
            </Snackbar>
          )}
          <RadioGroup
            alternatives={sortedTinkAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(account),
                  value: account.iban,
                };
              }
            )}
            value={
              selectedTinkAccount
                ? {
                    text: getAccountLabel(selectedTinkAccount),
                    value: selectedTinkAccount.iban,
                  }
                : undefined
            }
            header={intl.formatMessage(messages.accountLabel)}
            onChange={(alt) => {
              const selectedAccount = sortedTinkAccounts.find(
                (account) => account.iban === alt.value
              );
              monthlyContext.setState({
                tinkSelectedAccount: selectedAccount,
                externalAccount: selectedAccount?.iban,
              });
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.accountRequired)
              ),
            ]}
          />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="deposits.monthly.story.account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
